<script lang="ts">
  import { annotations } from '../store/vision-store';
  import { visionApi } from '../../../../../services/api/vision';

  // Media prop: base64 string + type
  export let media: { media: string; type: 'photo' | 'video' };

  type ProcessingState = 'idle' | 'processing' | 'success' | 'error';
  let processingState: ProcessingState = 'idle';
  let errorMessage: string | null = null;

  async function processImage(imageData: string) {
    const processingStart = performance.now();
    processingState = 'processing';
    errorMessage = null;

    try {
      console.log('[ImageProcessor] Starting vision analysis', {
        mediaType: media.type,
        imageSize: imageData.length,
        state: processingState
      });

      // Strip off "data:image/jpeg;base64," (etc.)
      const base64Data = imageData.split(',')[1];

      // Call your backend
      const visionResults = await visionApi.analyzeImage(base64Data);

      console.log('[ImageProcessor] Vision results received', {
        duration: ((performance.now() - processingStart) / 1000).toFixed(2) + 's',
        annotationCount: visionResults.annotations?.length,
        metadata: visionResults.metadata
      });

      if (visionResults.annotations?.length) {
        processingState = 'success';
        annotations.set(visionResults.annotations);
      } else {
        throw new Error('No annotations in response');
      }
    } catch (error) {
      processingState = 'error';
      errorMessage = error.message || 'Failed to analyze image';

      console.error('[ImageProcessor] Vision processing failed:', {
        error: error.message,
        processingTime: ((performance.now() - processingStart) / 1000).toFixed(2) + 's',
        state: processingState
      });

      // Clear any existing annotations on error
      annotations.set([]);

      // Emit error event up to parent if needed
      const errorEvent = new CustomEvent('vision-error', {
        detail: {
          message: errorMessage,
          timestamp: Date.now()
        }
      });
      dispatchEvent(errorEvent);
    }
  }

  // Only call processImage ONCE when idle, so we don’t loop if it fails
  $: if (media && media.type === 'photo' && processingState === 'idle') {
    console.log('[ImageProcessor] New media received for processing', {
      state: processingState
    });
    processImage(media.media);
  }
</script>

<!-- Show some UI states to the user -->
{#if processingState === 'processing'}
  <div class="processing-indicator">
    Analyzing image...
  </div>
{:else if processingState === 'error'}
  <div class="error-message">
    {errorMessage}
  </div>
{/if}
