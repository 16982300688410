<script>
  import { onMount, onDestroy } from 'svelte';
  import { auth } from '../../stores/auth';
  import { fade } from 'svelte/transition';
  import ConfirmDialog from '../common/ConfirmDialog.svelte';
  import SessionModal from '../common/SessionModal.svelte';

  let showConfirmDialog = false;
  let showSessionModal = false;
  let sessionMessage = '';
  let mounted = false;

  // Provide quick access to username and other user fields
  $: username = $auth?.user?.username || 'Guest';
  // e.g. if the user needs to complete a profile, you can show a "notification"
  $: hasIncompleteProfile = $auth?.user?.profileComplete === false;

  // Listen for session-expired events from anywhere in the app
  const handleSessionExpired = (event) => {
      sessionMessage = event.detail.message;
      showSessionModal = true;
  };

  onMount(() => {
      window.addEventListener('session-expired', handleSessionExpired);
      mounted = true;
  });

  onDestroy(() => {
      window.removeEventListener('session-expired', handleSessionExpired);
  });

  async function handleLogout() {
    try {
      // Either show a confirm dialog or do immediate logout
      // Here, we do immediate logout:
      await auth.logout();
      // Force clean reload
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  async function confirmLogout(confirmed) {
    if (!confirmed) {
      showConfirmDialog = false;
      return;
    }

    try {
      await auth.logout();
      window.location.href = '/login'; // Force clean reload
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      showConfirmDialog = false;
    }
  }
</script>

{#if mounted}
  <div class="header-container" transition:fade>
    <div class="header-left">
      <!-- Placeholder or navigation toggle if needed -->
    </div>
    
    <div class="header-center">
      <button
        class="username-btn"
        class:has-notification={hasIncompleteProfile}
        on:click={() => console.log('Profile modal or user menu could open here')}
      >
        {username}
      </button>
    </div>
    
    <div class="header-right">
      <button
        class="logout-button"
        on:click={handleLogout}
      >
        Logout
      </button>
    </div>
  </div>

  <!-- Optional confirm dialog usage -->
  {#if showConfirmDialog}
    <ConfirmDialog
      title="Confirm Logout"
      message="Are you sure you want to log out?"
      onConfirm={() => confirmLogout(true)}
      onCancel={() => confirmLogout(false)}
    />
  {/if}

  <!-- Session expired modal -->
  {#if showSessionModal}
    <SessionModal
      message={sessionMessage}
      onClose={() => showSessionModal = false}
    />
  {/if}
{/if}

<style>
  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: sticky;
    top: 0;
    z-index: 100;
    height: 64px;
  }

  .header-left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .header-center {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .username-btn {
    position: relative;
    padding: 0.5rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: #2196F3;
    background: transparent;
    border: 2px solid #2196F3;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .username-btn:hover {
    color: white;
    background: #2196F3;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
  }

  .username-btn:active {
    transform: translateY(0);
  }

  .has-notification::after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    width: 10px;
    height: 10px;
    background: #f44336;
    border: 2px solid white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(244, 67, 54, 0.2);
  }

  .logout-button {
    padding: 0.5rem 1.25rem;
    margin-left: 1rem;
    font-size: 0.95rem;
    font-weight: 500;
    color: #64748b;
    background: #f1f5f9;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .logout-button:hover {
    background: #e2e8f0;
    color: #475569;
    transform: translateY(-1px);
  }

  .logout-button:active {
    transform: translateY(0);
  }

  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .header-container {
      padding: 1rem;
    }

    .username-btn {
      font-size: 1rem;
      padding: 0.4rem 1rem;
    }

    .logout-button {
      padding: 0.4rem 1rem;
      font-size: 0.9rem;
    }
  }
</style>
