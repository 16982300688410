<script>
  import { onMount, onDestroy } from 'svelte';
  import { auth } from '../../stores/auth';
  import { createEventDispatcher } from 'svelte';
  import { navigate } from 'svelte-routing';

  /**
   * Dispatch success when the user logs in successfully,
   * so the parent component (AuthModal or others) can respond
   */
  const dispatch = createEventDispatcher();

  let username = '';
  let password = '';
  let loading = false;
  let error = null;
  let mounted = false;
  let navigationAttempted = false;

  onMount(() => {
    mounted = true;
    console.log('Login component mounted');
  });

  // Subscribe to the auth store
  const unsubscribe = auth.subscribe(($auth) => {
    try {
      loading = $auth.isLoading;
      error = $auth.error;
      
      // If already authenticated and we haven't navigated yet, go to portfolio
      if (mounted && $auth.isAuthenticated && !navigationAttempted) {
        handleSuccessfulLogin();
      }
    } catch (err) {
      console.error('Auth store subscription error:', err);
      error = 'System error occurred. Please try again.';
    }
  });

  onDestroy(() => {
    try {
      unsubscribe();
      username = '';
      password = '';
    } catch (err) {
      console.error('Cleanup error:', err);
    }
  });

  /**
   * Called after successful login to navigate to your portfolio
   */
  async function handleSuccessfulLogin() {
    try {
      navigationAttempted = true;
      username = '';
      password = '';
      dispatch('success');
      
      // Wait a moment for store updates
      await new Promise(resolve => setTimeout(resolve, 50));
      
      console.log('Login successful, navigating to portfolio...');
      
      try {
        // If your portfolio is at "/", update to: navigate('/')
        // If your portfolio is at "/portfolio", do: navigate('/portfolio')
        await navigate('/', { replace: true });
      } catch (navError) {
        console.error('Navigation failed, falling back to window.location:', navError);
        // Full page reload as fallback
        window.location.href = '/';
      }
    } catch (err) {
      console.error('Error during post-login navigation:', err);
    }
  }

  /**
   * Form submission for login
   */
  async function handleSubmit() {
    if (loading || navigationAttempted) return;
    
    try {
      loading = true;
      error = null;
      navigationAttempted = true;
      
      console.log('Login attempt:', { username });
      const response = await auth.login(username, password);
      
      if (response?.access) {
        console.log('Login successful');
        // Clear fields
        username = '';
        password = '';

        // Notify parent
        dispatch('success');

        // Immediately navigate to portfolio (or "/")
        await navigate('/', { replace: true });
      }
    } catch (err) {
      console.error('Login failed:', err);
      error = err.message || 'Login failed';
      navigationAttempted = false;
    } finally {
      loading = false;
    }
  }
</script>

<form on:submit|preventDefault={handleSubmit} class="auth-form">
  <h2>Login</h2>
  
  <div class="form-group">
    <label for="username">Username</label>
    <input
      type="text"
      id="username"
      bind:value={username}
      placeholder="Enter username"
      disabled={loading}
      required
    />
  </div>

  <div class="form-group">
    <label for="password">Password</label>
    <input
      type="password"
      id="password"
      bind:value={password}
      placeholder="Enter password"
      disabled={loading}
      required
    />
  </div>

  {#if error}
    <div class="error-message" role="alert">
      {error}
    </div>
  {/if}

  <button
    type="submit"
    disabled={loading || !username || !password}
    class:loading={loading}
  >
    {loading ? 'Logging in...' : 'Login'}
  </button>
</form>

<style>
  .auth-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #2c3e50;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #2c3e50;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
  }

  input:focus {
    outline: none;
    border-color: #007bff;
  }

  input:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
  }

  button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
  }

  button:not(:disabled):hover {
    background-color: #0056b3;
    transform: translateY(-1px);
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
  }

  .loading {
    opacity: 0.8;
    cursor: wait;
  }

  .error-message {
    color: #dc3545;
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: #f8d7da;
    border-radius: 4px;
    text-align: center;
    font-size: 0.9rem;
  }
</style>
