<script>
  import { createEventDispatcher, onDestroy } from 'svelte';
  import VisionOrchestrator from '../ai/VisionOrchestrator.svelte';
  import '../styles/media.css';

  export let media = null;
  export let type = null; // 'photo' or 'video'

  const dispatch = createEventDispatcher();
  let mediaPreviewElement;
  let showVisionOrchestrator = false;
  let isProcessing = false;

  function clearMedia() {
    if (mediaPreviewElement) {
      mediaPreviewElement.pause();
      mediaPreviewElement.removeAttribute('src');
      mediaPreviewElement.load();
    }

    if (type === 'video' && media?.startsWith('blob:')) {
      URL.revokeObjectURL(media);
    }

    dispatch('clear');
  }

  async function generateDescription() {
    isProcessing = true;
    showVisionOrchestrator = true;
  }

  onDestroy(() => {
    if (type === 'video' && media?.startsWith('blob:')) {
      URL.revokeObjectURL(media);
    }
  });
</script>

<div class="media-preview-content">
  <div class="preview-area">
    {#if type === 'photo'}
      <img
        src={media}
        alt="Captured media"
        class="captured-image"
      />
    {:else if type === 'video'}
      <video
        bind:this={mediaPreviewElement}
        class="captured-video"
        src={media}
        autoplay
        loop
        muted
        playsinline
        controls
      >
        <track kind="captions" />
      </video>
    {/if}
  </div>

  <div class="media-controls">
    <button
      class="media-button"
      on:click={clearMedia}
    >
      Clear
    </button>
    
    <button 
      class="media-button primary"
      on:click={generateDescription}
      disabled={isProcessing}
    >
      {#if isProcessing}
        <span class="button-content">
          <span class="spinner"></span>
          <span>Generating...</span>
        </span>
      {:else}
        Generate Description
      {/if}
    </button>
  </div>

  {#if showVisionOrchestrator}
    <VisionOrchestrator 
      media={media} 
      mediaType={type}
      on:complete={() => isProcessing = false}
    />
  {/if}
</div>