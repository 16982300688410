<!-- Editor.svelte -->
<script lang="ts">
    import { userInput, generatedSteps, reviewedAnnotations } from '../ai/store/state';
    import '../styles/description.css';
    
    let input = '';
    let debugInfo = {
        approvedAnnotations: 0,
        hasUserInput: false,
        stepsGenerated: 0
    };

    window.addEventListener('description-save', (event: CustomEvent) => {
        input = event.detail;
        userInput.set(input);
    });

    $: {
        console.group('[Editor] Processing User Input');
        console.log('Current input:', input);
        $userInput = input;
        debugInfo.hasUserInput = input.length > 0;
        console.log('Debug info updated:', debugInfo);
        console.groupEnd();
    }

    $: approvedAnnotations = $reviewedAnnotations.filter(a => a.status === 'approved');
    $: {
        console.group('[Editor] Processing Approved Annotations');
        debugInfo.approvedAnnotations = approvedAnnotations.length;
        console.log('Total approved annotations:', approvedAnnotations.length);
        console.log('Approved annotations:', approvedAnnotations);
        if (approvedAnnotations.length > 0) {
            console.log('Latest approved annotation:', approvedAnnotations[approvedAnnotations.length - 1]);
        }
        console.groupEnd();
    }

    $: {
        debugInfo.stepsGenerated = $generatedSteps.length;
        if ($generatedSteps.length > 0) {
            console.group('[Editor] Steps Status');
            console.log('Total steps:', $generatedSteps.length);
            console.log('Steps:', $generatedSteps);
            console.groupEnd();
        }
    }

    function updateStepStatus(index: number, newStatus: 'approved' | 'rejected') {
        console.group('[Editor] Updating Step Status');
        console.log('Step index:', index);
        console.log('New status:', newStatus);
        
        generatedSteps.update(steps => {
            const updatedSteps = [...steps];
            updatedSteps[index] = { 
                ...updatedSteps[index], 
                status: newStatus,
                updatedAt: new Date()
            };
            console.log('Updated steps:', updatedSteps);
            return updatedSteps;
        });
        
        console.groupEnd();
    }

    function formatTimestamp(date: Date): string {
        return date.toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit' 
        });
    }

    function sortAnnotations(annotations) {
        return [...annotations].sort((a, b) => b.confidence - a.confidence);
    }
</script>

<div class="description-container">
    
    <div class="editor-area">
  <textarea
    class="description-input"
    bind:value={input}
    placeholder="Enter or generate a description"
    rows="4"
    aria-label="Project description input"
  ></textarea>

        {#if approvedAnnotations.length > 0}
            <div class="suggestions-panel">
                <h4>Approved Insights</h4>
                {#each sortAnnotations(approvedAnnotations) as annotation (annotation.id)}
                    <div class="annotation-item">
                        <p>{annotation.content}</p>
                        <div class="description-debug-info">
                            {#if annotation.mediaType === 'video'}
                                <span class="timestamp">Time: {annotation.timestamp}s</span>
                            {/if}
                            <span class="confidence">
                                Confidence: {(annotation.confidence * 100).toFixed(1)}%
                            </span>
                            <span class="timestamp">
                                Added: {formatTimestamp(annotation.reviewedAt)}
                            </span>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
    </div>

    {#if $generatedSteps.length > 0}
        <div class="suggestions-panel">
            <h4>Generated Steps</h4>
            {#each $generatedSteps as step, index}
                <div class="annotation-item">
                    <p>{step.content}</p>
                    <div class="description-debug-info">
                        Status: {step.status}
                        {#if step.updatedAt}
                            <span class="timestamp">
                                Updated: {formatTimestamp(step.updatedAt)}
                            </span>
                        {/if}
                    </div>
                    <div class="description-controls">
                        <button
                            class="description-button"
                            on:click={() => updateStepStatus(index, 'approved')}
                            disabled={step.status === 'approved'}
                        >
                            Approve
                        </button>
                        <button
                            class="description-button"
                            on:click={() => updateStepStatus(index, 'rejected')}
                            disabled={step.status === 'rejected'}
                        >
                            Reject
                        </button>
                    </div>
                </div>
            {/each}
        </div>
    {/if}
</div>

<style>
    textarea {
    composes: description-input;
    min-height: 120px;
    resize: vertical;
  }
</style>