// src/stores/sessionManager.js
import { writable } from 'svelte/store';
import { auth } from './auth';

function createSessionManager() {
  const { subscribe, set, update } = writable({
    showSessionModal: false,
    lastError: null,
    pendingRequests: new Set(),
  });

  let store;
  subscribe(value => {
    store = value;
  });

  return {
    subscribe,
    
    handleSessionExpired(error) {
      // Update store to show session modal
      update(state => ({
        ...state,
        showSessionModal: true,
        lastError: error
      }));

      // Return a promise that will resolve when reauthorized
      return new Promise((resolve, reject) => {
        const unsubscribe = auth.subscribe(($auth) => {
          if ($auth.isAuthenticated) {
            unsubscribe();
            update(state => ({
              ...state,
              showSessionModal: false,
              lastError: null
            }));
            resolve();
          }
        });

        // Add cleanup for rejected/cancelled reauth attempts
        setTimeout(() => {
          unsubscribe();
          reject(new Error('Reauthorization timeout'));
        }, 5 * 60 * 1000); // 5 minute timeout
      });
    },

    // Called when user successfully reauthorizes
    async handleReauthorized() {
      update(state => ({
        ...state,
        showSessionModal: false,
        lastError: null
      }));

      // Retry any pending requests
      const pendingRequests = [...store.pendingRequests];
      store.pendingRequests.clear();

      for (const request of pendingRequests) {
        try {
          await request();
        } catch (error) {
          console.error('Failed to retry request after reauth:', error);
        }
      }
    },

    // Add a request to retry after reauth
    addPendingRequest(request) {
      update(state => ({
        ...state,
        pendingRequests: new Set([...state.pendingRequests, request])
      }));
    },

    // Close the session modal
    closeSessionModal() {
      update(state => ({
        ...state,
        showSessionModal: false,
        lastError: null,
        pendingRequests: new Set()
      }));
    },

    // Reset the session manager state
    reset() {
      set({
        showSessionModal: false,
        lastError: null,
        pendingRequests: new Set()
      });
    }
  };
}

export const sessionManager = createSessionManager();