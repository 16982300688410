<!-- src/components/workorders/WorkOrderList.svelte -->
<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import { workOrderStore } from '../../stores/work-order';
    import { projectStore } from '../../stores/project';
    import { worker } from '../../stores/worker';
    import WorkOrderForm from './WorkOrderForm.svelte';

    const dispatch = createEventDispatcher();
    let showForm = false;
    let isEdit = false;
    let editingWorkOrder = null;

    let searchTerm = '';
    let statusFilter = 'ALL';
    let priorityFilter = 'ALL';

    // Derived filtering
    $: filteredWorkOrders = $workOrderStore.items
        .filter(wo => statusFilter === 'ALL' || wo.status === statusFilter)
        .filter(wo => priorityFilter === 'ALL' || wo.priority === priorityFilter)
        .filter(wo => {
            if (!searchTerm) return true;
            const s = searchTerm.toLowerCase();
            return (
                wo.title.toLowerCase().includes(s) ||
                wo.description?.toLowerCase().includes(s)
            );
        });

    onMount(async () => {
        await Promise.all([
            workOrderStore.load(),
            projectStore.load(),
            worker.loadWorkers()
        ]);
    });

    function openCreateForm() {
        isEdit = false;
        editingWorkOrder = null;
        showForm = true;
    }

    function openEditForm(wo) {
        isEdit = true;
        // shallow-copy the existing order
        editingWorkOrder = JSON.parse(JSON.stringify(wo));
        showForm = true;
    }

    function handleWorkOrderSaved(event) {
        // user either created or updated a WO
        showForm = false;
        // refresh logic if needed
        dispatch('workOrderCreated', event.detail);
    }

    // Mark a work order as COMPLETED
    async function completeWorkOrder(id) {
        try {
            // Just update the status
            await workOrderStore.update(id, { status: 'COMPLETED' });
            // Reload projects so "completed" count updates
            await projectStore.load();
        } catch (err) {
            console.error('Error completing work order:', err);
        }
    }

    // Delete a work order with confirmation
    async function deleteWorkOrder(id, title) {
        if (!confirm(`Are you sure you want to delete "${title}"?`)) {
            return;
        }
        try {
            await workOrderStore.delete(id);
            await projectStore.load();
        } catch (err) {
            console.error('Error deleting work order:', err);
        }
    }

    function getStatusBadgeClass(status) {
        switch (status) {
            case 'DRAFT':       return 'draft';
            case 'IN_PROGRESS': return 'in-progress';
            case 'ON_HOLD':     return 'on-hold';
            case 'COMPLETED':   return 'completed';
            case 'CANCELLED':   return 'cancelled';
            case 'PENDING':     return 'pending';
            case 'SCHEDULED':   return 'scheduled';
            default:            return 'default';
        }
    }

    function getPriorityBadgeClass(priority) {
        switch (priority) {
            case 'LOW':     return 'low';
            case 'MEDIUM':  return 'medium';
            case 'HIGH':    return 'high';
            case 'URGENT':  return 'urgent';
            default:        return 'medium';
        }
    }

    function formatDate(d) {
        if (!d) return '';
        return new Date(d).toLocaleString();
    }

    async function handleComplete(workOrderId) {
    try {
        await workOrderStore.completeWork(workOrderId);
        // Optionally reload the worker's work orders
        await loadWorkers();
    } catch (err) {
        error = err.message;
    }
}
</script>

<div class="work-order-list">
    <div class="list-header">
        <h2>Work Orders</h2>
        <button class="new-button" on:click={openCreateForm}>
            + New Work Order
        </button>
    </div>

    {#if showForm}
        <div class="modal-overlay">
            <div class="modal-content">
                <WorkOrderForm
                    isEdit={isEdit}
                    existingWorkOrder={editingWorkOrder}
                    on:close={() => (showForm = false)}
                    on:saved={handleWorkOrderSaved}
                />
            </div>
        </div>
    {:else}
        <div class="filters">
            <input
                type="text"
                placeholder="Search work orders..."
                bind:value={searchTerm}
                class="search-input"
            />
            <div class="filter-group">
                <select bind:value={statusFilter} class="filter-select">
                    <option value="ALL">All Statuses</option>
                    <option value="DRAFT">Draft</option>
                    <option value="PENDING">Pending</option>
                    <option value="SCHEDULED">Scheduled</option>
                    <option value="IN_PROGRESS">In Progress</option>
                    <option value="ON_HOLD">On Hold</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="CANCELLED">Cancelled</option>
                </select>
                <select bind:value={priorityFilter} class="filter-select">
                    <option value="ALL">All Priorities</option>
                    <option value="LOW">Low</option>
                    <option value="MEDIUM">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="URGENT">Urgent</option>
                </select>
            </div>
        </div>

        {#if $workOrderStore.loading}
            <div class="loading">Loading work orders...</div>
        {:else if $workOrderStore.error}
            <div class="error-message">{$workOrderStore.error}</div>
        {:else if filteredWorkOrders.length === 0}
            <div class="empty-message">
                {searchTerm || statusFilter !== 'ALL' || priorityFilter !== 'ALL' 
                    ? 'No work orders found' 
                    : 'No work orders yet'}
            </div>
        {:else}
            <ul class="work-order-items">
                {#each filteredWorkOrders as wo}
                    {@const proj = $projectStore.items.find(p => p.id === wo.project)}
                    {@const assignedWorkers = wo.assigned_workers?.map(aw =>
                        $worker.workers.find(wx => wx.id === aw.worker_id)
                    ).filter(Boolean) || []}

                    <li class="work-order-item">
                        <!-- Show project name up top -->
                        {#if proj}
                            <div class="wo-project-banner">
                                <span class="project-badge">
                                    Project: {proj.title}
                                </span>
                            </div>
                        {/if}

                        <div class="wo-header">
                            <h3 class="wo-title">{wo.title}</h3>
                            <span class="status-badge {getStatusBadgeClass(wo.status)}">
                                {wo.status}
                            </span>
                        </div>

                        <div class="wo-meta">
                            <span class="priority-badge {getPriorityBadgeClass(wo.priority)}">
                                {wo.priority}
                            </span>
                            {#if wo.status === 'IN_PROGRESS'}
                                <button
                                    class="complete-btn"
                                    on:click={() => completeWorkOrder(wo.id)}
                                >
                                    Complete
                                </button>
                            {/if}
                        </div>

                        {#if wo.description}
                            <p class="wo-description">{wo.description}</p>
                        {/if}

                        {#if assignedWorkers.length > 0}
                            <div class="assigned-workers">
                                <span class="detail-label">Assigned:</span>
                                <div class="worker-tags">
                                    {#each assignedWorkers as w}
                                        <span class="worker-tag">{w.name}</span>
                                    {/each}
                                </div>
                            </div>
                        {/if}

                        <!-- Edit/Delete Buttons -->
                        <div class="wo-actions">
                            <button class="edit-btn" on:click={() => openEditForm(wo)}>
                                Edit
                            </button>
                            <button class="delete-btn" on:click={() => deleteWorkOrder(wo.id, wo.title)}>
                                Delete
                            </button>
                        </div>
                    </li>
                {/each}
            </ul>
        {/if}
    {/if}
</div>

<style>
    .work-order-list {
        width: 100%;
        max-width: 1000px;
    }

    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .list-header h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .new-button {
        padding: 0.5rem 1rem;
        background: #2196F3;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .new-button:hover {
        background: #1976D2;
    }

    .modal-overlay {
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        background: white;
        padding: 2rem;
        border-radius: 0.5rem;
        max-width: 90%;
        max-height: 90vh;
        overflow-y: auto;
    }

    .filters {
        display: grid;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .search-input,
    .filter-select {
        padding: 0.5rem;
        border: 1px solid #e2e8f0;
        border-radius: 4px;
        font-size: 0.875rem;
    }

    .filter-group {
        display: flex;
        gap: 1rem;
    }

    .work-order-items {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        gap: 1rem;
    }

    .work-order-item {
        padding: 1.5rem;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        background: white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .wo-project-banner {
        background: #f3f4f6;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        font-size: 0.875rem;
        color: #374151;
    }

    .project-badge {
        font-weight: 500;
    }

    .wo-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    .wo-title {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .status-badge {
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .wo-meta {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .priority-badge {
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .complete-btn {
        background: #2563eb;
        color: white;
        border: none;
        padding: 0.25rem 0.75rem;
        border-radius: 4px;
        font-size: 0.75rem;
        cursor: pointer;
    }

    .complete-btn:hover {
        background: #1d4ed8;
    }

    .wo-description {
        margin: 0;
        color: #4b5563;
        font-size: 0.875rem;
    }

    .assigned-workers {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 0.875rem;
    }

    .worker-tags {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    .worker-tag {
        background: #f3f4f6;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        font-size: 0.75rem;
    }

    .wo-actions {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
    }

    .edit-btn {
        background: #06b6d4;
        color: white;
        border: none;
        padding: 0.25rem 0.75rem;
        border-radius: 4px;
        font-size: 0.75rem;
        cursor: pointer;
    }

    .edit-btn:hover {
        background: #0891b2;
    }

    .delete-btn {
        background: #dc2626;
        color: white;
        border: none;
        padding: 0.25rem 0.75rem;
        border-radius: 4px;
        font-size: 0.75rem;
        cursor: pointer;
    }

    .delete-btn:hover {
        background: #b91c1c;
    }

    /* Status Badge Colors */
    .status-badge.draft        { background: #f3f4f6; color: #4b5563; }
    .status-badge.in-progress  { background: #dcfce7; color: #166534; }
    .status-badge.on-hold      { background: #fef3c7; color: #92400e; }
    .status-badge.completed    { background: #e5e7eb; color: #6b7280; }
    .status-badge.cancelled    { background: #fee2e2; color: #dc2626; }
    .status-badge.pending      { background: #dbeafe; color: #1e40af; }
    .status-badge.scheduled    { background: #e9d5ff; color: #6d28d9; }

    /* Priority Badge Colors */
    .priority-badge.low      { background: #f3f4f6; color: #4b5563; }
    .priority-badge.medium   { background: #dbeafe; color: #1e40af; }
    .priority-badge.high     { background: #fef3c7; color: #92400e; }
    .priority-badge.urgent   { background: #fee2e2; color: #dc2626; }

    .loading,
    .empty-message,
    .error-message {
        text-align: center;
        padding: 2rem;
        color: #6b7280;
    }

    .error-message {
        color: #dc2626;
        background: #fee2e2;
        border-radius: 4px;
    }
</style>
