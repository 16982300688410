<script>
    import { createEventDispatcher } from 'svelte';
    import { clientStore } from '../../stores/client';
    import { worker } from '../../stores/worker';
    import { projectStore } from '../../stores/project';
    import WorkOrderForm from '../workorders/WorkOrderForm.svelte';

    const dispatch = createEventDispatcher();

    // Our form data
    let formData = {
        title: '',
        description: '',
        status: 'PLANNING',
        client_id: '',
        start_date: '',
        estimated_end_date: '',
        budget: null,
        workers: []
    };

    let clients = [];
    let availableWorkers = [];
    let isLoading = false;
    let error = null;
    let showWorkOrderForm = false;

    // Basic validation
    $: isValid = formData.title.trim();

    // Potential form errors
    $: formErrors = {
        title: !formData.title.trim() && 'Title is required',
        budget: formData.budget && isNaN(parseFloat(formData.budget)) && 'Invalid budget amount'
    };

    // Load data on mount
    async function loadFormData() {
        try {
            await clientStore.load();
            await worker.loadWorkers();
            clients = $clientStore.items;
            availableWorkers = $worker.workers;
        } catch (err) {
            error = 'Failed to load form data';
            console.error('Error loading form data:', err);
        }
    }

    loadFormData();

    function handleBudgetInput(e) {
        // Remove any non-digits and non-decimal points
        let value = e.target.value.replace(/[^\d.]/g, '');
        
        // Ensure only one decimal point
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join('');
        }
        
        // Add commas for thousands
        if (value) {
            const num = parseFloat(value);
            if (!isNaN(num)) {
                // Store the raw number
                formData.budget = num;
                // Format display value with commas
                e.target.value = num.toLocaleString('en-US');
            }
        } else {
            formData.budget = null;
            e.target.value = '';
        }
    }

    function toggleWorker(workerId) {
        const index = formData.workers.indexOf(workerId);
        if (index === -1) {
            formData.workers = [...formData.workers, workerId];
        } else {
            formData.workers = formData.workers.filter(id => id !== workerId);
        }
    }

    async function handleSubmit() {
        if (!isValid) {
            error = 'Please correct the form errors';
            return;
        }

        isLoading = true;
        error = null;

        try {
            // Prepare final data - only send what the backend expects
            const projectData = {
                title: formData.title.trim(),
                description: formData.description.trim() || '',
                status: formData.status,
                client: formData.client_id || null,  // Match the serializer field name
                start_date: formData.start_date || null,
                end_date: formData.estimated_end_date || null,
                budget: formData.budget ? parseFloat(formData.budget) : null
            };

            // Create the project first
            const newProject = await projectStore.add(projectData);

            // Add workers to the project
            for (const workerId of formData.workers) {
                await projectStore.addWorker(newProject.id, workerId);
            }

            // Show work order form after project creation
            showWorkOrderForm = true;

            dispatch('created', { project: newProject });
        } catch (err) {
            console.error('Error creating project:', err);
            error = err.message || 'Failed to create project';
        } finally {
            isLoading = false;
        }
    }

    function handleWorkOrderSaved() {
        dispatch('close');
    }
</script>

<div class="project-form">
    <!-- Header -->
    <div class="form-header">
        <button 
            type="button"
            class="back-btn"
            on:click={() => dispatch('close')}
        >
            ←
        </button>
        <h2>Create New Project</h2>
        <button
            type="submit"
            class="save-btn"
            disabled={!isValid || isLoading}
            on:click={handleSubmit}
        >
            {isLoading ? 'Creating...' : 'Save'}
        </button>
    </div>

    <!-- Error Message -->
    {#if error}
        <div class="error-message" role="alert">
            {error}
        </div>
    {/if}

    <!-- Form -->
    <form on:submit|preventDefault={handleSubmit} class="form-content">
        <div class="form-section">
            <h3>Project Details</h3>

            <div class="form-group">
                <label for="title">Project Title <span class="required">*</span></label>
                <input
                    type="text"
                    id="title"
                    bind:value={formData.title}
                    placeholder="Enter project title"
                    class:error={formErrors.title}
                    required
                />
                {#if formErrors.title}
                    <span class="error-text">{formErrors.title}</span>
                {/if}
            </div>

            <div class="form-group">
                <label for="client">Client</label>
                <select 
                    id="client" 
                    bind:value={formData.client_id}
                >
                    <option value="">Select a client</option>
                    {#each clients as c}
                        <option value={c.id}>{c.name}</option>
                    {/each}
                </select>
            </div>

            <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    id="description"
                    bind:value={formData.description}
                    placeholder="Enter project description"
                    rows="4"
                />
            </div>

            <div class="form-row">
                <div class="form-group">
                    <label for="start_date">Start Date</label>
                    <input
                        type="date"
                        id="start_date"
                        bind:value={formData.start_date}
                    />
                </div>

                <div class="form-group">
                    <label for="estimated_end_date">Estimated End Date</label>
                    <input
                        type="date"
                        id="estimated_end_date"
                        bind:value={formData.estimated_end_date}
                    />
                </div>
            </div>

            <div class="form-group">
                <label for="budget">Budget</label>
                <input
                    type="text"
                    id="budget"
                    placeholder="1,000.00"
                    on:input={handleBudgetInput}
                />
                {#if formErrors.budget}
                    <span class="error-text">{formErrors.budget}</span>
                {/if}
            </div>

            <div class="form-group">
                <label for="status">Status</label>
                <select id="status" bind:value={formData.status}>
                    <option value="PLANNING">Planning</option>
                    <option value="IN_PROGRESS">In Progress</option>
                    <option value="ON_HOLD">On Hold</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="CANCELLED">Cancelled</option>
                </select>
            </div>
        </div>

        <div class="form-section">
            <h3>Assign Workers</h3>
            <div class="workers-grid">
                {#each availableWorkers as w}
                    <label class="worker-checkbox">
                        <input
                            type="checkbox"
                            checked={formData.workers.includes(w.id)}
                            on:change={() => toggleWorker(w.id)}
                        />
                        <span class="worker-info">
                            <span class="worker-name">{w.name}</span>
                            {#if w.title}
                                <span class="worker-title">{w.title}</span>
                            {/if}
                            <span class="worker-status {w.status.toLowerCase()}">
                                {w.status}
                            </span>
                        </span>
                    </label>
                {/each}
            </div>
        </div>

        <div class="form-section">
            <h3>Work Orders</h3>
            {#if showWorkOrderForm}
                <div class="form-content-workorder">
                    <WorkOrderForm 
                        on:saved={handleWorkOrderSaved}
                        on:close={() => showWorkOrderForm = false}
                    />
                </div>
            {/if}
        </div>
    </form>
</div>


<style>
.form-content-workorder {
        background: #f8fafc;
        padding: 1.5rem;
        border-radius: 0.5rem;
        margin-top: 1rem;
    }
    .project-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: white;
    }
    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }
    .form-header h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0;
    }
    .back-btn,
    .save-btn {
        height: 48px;
        min-width: 48px;
        border: none;
        border-radius: 0.5rem;
        font-size: 1.125rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }
    .back-btn {
        background: transparent;
        font-size: 1.5rem;
    }
    .back-btn:hover {
        background: #f3f4f6;
    }
    .save-btn {
        background: #2563eb;
        color: white;
    }
    .save-btn:disabled {
        background: #93c5fd;
        cursor: not-allowed;
    }
    .form-content {
        flex: 1;
        padding: 1rem;
        overflow-y: auto;
    }
    .form-section {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e5e7eb;
    }
    .form-section h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 1rem 0;
        color: #111827;
    }
    .form-group {
        margin-bottom: 1.5rem;
    }
    .form-row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
        margin-bottom: 1rem;
    }
    label {
        display: block;
        font-weight: 500;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }
    .required {
        color: #dc2626;
    }
    input,
    select,
    textarea {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        font-size: 1rem;
        background: white;
        height: 48px;
    }
    textarea {
        height: auto;
        min-height: 96px;
        resize: vertical;
    }
    input.error,
    select.error,
    textarea.error {
        border-color: #dc2626;
    }
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }
    .error-message {
        margin: 1rem;
        padding: 1rem;
        background: #fee2e2;
        color: #dc2626;
        border-radius: 0.5rem;
        font-size: 0.875rem;
    }
    .error-text {
        color: #dc2626;
        font-size: 0.875rem;
        margin-top: 0.25rem;
        display: block;
    }
    .currency-input {
        position: relative;
    }
    .currency-symbol {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: #6b7280;
    }
    .currency-input input {
        padding-left: 2rem;
    }
    .workers-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 0.75rem;
    }
    .worker-checkbox {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.2s;
    }
    .worker-checkbox:hover {
        border-color: #d1d5db;
        background: #f9fafb;
    }
    .worker-info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }
    .worker-name {
        font-weight: 500;
    }
    .worker-title {
        color: #6b7280;
        font-size: 0.875rem;
    }
    .worker-status {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        border-radius: 9999px;
        font-size: 0.75rem;
        font-weight: 500;
    }
    .worker-status.available { background: #dcfce7; color: #166534; }
    .worker-status.busy { background: #fef3c7; color: #92400e; }
    .worker-status.on_leave { background: #dbeafe; color: #1e40af; }
    .worker-status.inactive { background: #f3f4f6; color: #4b5563; }
    .work-orders-list {
        display: grid;
        gap: 0.75rem;
        margin-bottom: 1.5rem;
    }
    .work-order-item {
        padding: 1rem;
        background: #f8fafc;
        border-radius: 0.5rem;
        border: 1px solid #e5e7eb;
    }
    .work-order-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
    }
    .work-order-header h4 {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
    }
    .remove-button {
        background: none;
        border: none;
        color: #dc2626;
        cursor: pointer;
        font-size: 1.25rem;
        padding: 0.25rem;
        line-height: 1;
        border-radius: 0.25rem;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .remove-button:hover {
        background: #fee2e2;
    }
    .priority-badge {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        border-radius: 9999px;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0.5rem 0;
    }
    .priority-badge.low { background: #f3f4f6; color: #4b5563; }
    .priority-badge.medium { background: #dbeafe; color: #1e40af; }
    .priority-badge.high { background: #fef3c7; color: #92400e; }
    .priority-badge.urgent { background: #fee2e2; color: #dc2626; }
    .description {
        margin: 0.5rem 0 0;
        color: #6b7280;
        font-size: 0.875rem;
        line-height: 1.5;
    }
    .add-work-order {
        background: #f8fafc;
        padding: 1.5rem;
        border-radius: 0.5rem;
        margin-top: 1rem;
    }
    .add-button {
        width: 100%;
        padding: 0.75rem;
        background: #4b5563;
        color: white;
        border: none;
        border-radius: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        height: 48px;
    }
    .add-button:hover:not(:disabled) {
        background: #374151;
    }
    .add-button:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    /* Mobile optimizations */
    @media screen and (max-width: 640px) {
        .form-content {
            padding-bottom: 4rem; /* Extra space for keyboard */
        }
        input,
        select,
        textarea {
            font-size: 16px; /* Prevent iOS zoom */
        }
        .form-row {
            grid-template-columns: 1fr;
        }
        .workers-grid {
            grid-template-columns: 1fr;
        }
        .worker-checkbox {
            padding: 1rem;
        }
        .remove-button {
            padding: 0.5rem; /* Larger touch target */
        }
        .add-work-order {
            padding: 1rem;
            margin: 0 -1rem;
            border-radius: 0;
        }
    }
</style>
