/**
 * project-service.js
 * Handles all project-related API interactions
 * 
 * @typedef {Object} ProjectData
 * @property {string} title - Project title
 * @property {string} [description] - Project description
 * @property {string} [status] - Project status
 * @property {string} [client_id] - Associated client ID
 * @property {Date} [start_date] - Project start date
 * @property {Date} [end_date] - Project end date
 * @property {number} [budget] - Project budget
 */

import { API_CONFIG, buildQueryString } from './config';
import { apiClient } from './client';

export const projectService = {
    /**
     * Get projects with optional filtering
     * @param {Object} [params] - Query parameters
     */
    async getProjects(params = {}) {
        try {
            const queryString = buildQueryString(params);
            return await apiClient.request(`${API_CONFIG.ENDPOINTS.PROJECT.LIST}${queryString}`);
        } catch (error) {
            console.error('Error fetching projects:', error);
            throw error;
        }
    },

    /**
     * Get a specific project by ID
     * @param {string} projectId - Project ID
     */
    async getProjectById(projectId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.UPDATE(projectId),
                { method: 'GET' }
            );
        } catch (error) {
            console.error('Error fetching project:', error);
            throw error;
        }
    },

    /**
     * Create a new project
     * @param {ProjectData} projectData - Project data
     */
    async createProject(projectData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.CREATE,
                {
                    method: 'POST',
                    body: JSON.stringify(projectData)
                }
            );
        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    },

    /**
     * Update an existing project
     * @param {string} projectId - Project ID
     * @param {ProjectData} projectData - Updated project data
     */
    async updateProject(projectId, projectData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.UPDATE(projectId),
                {
                    method: 'PUT',
                    body: JSON.stringify(projectData)
                }
            );
        } catch (error) {
            console.error('Error updating project:', error);
            throw error;
        }
    },

    /**
     * Partially update a project
     * @param {string} projectId - Project ID
     * @param {Partial<ProjectData>} partialData - Partial project data
     */
    async patchProject(projectId, partialData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.UPDATE(projectId),
                {
                    method: 'PATCH',
                    body: JSON.stringify(partialData)
                }
            );
        } catch (error) {
            console.error('Error patching project:', error);
            throw error;
        }
    },

    /**
     * Delete a project
     * @param {string} projectId - Project ID
     */
    async deleteProject(projectId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.DELETE(projectId),
                {
                    method: 'DELETE'
                }
            );
        } catch (error) {
            console.error('Error deleting project:', error);
            throw error;
        }
    },

    /**
     * Get all works associated with a project
     * @param {string} projectId - Project ID
     */
    async getProjectWorks(projectId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.WORKS(projectId)
            );
        } catch (error) {
            console.error('Error fetching project works:', error);
            throw error;
        }
    },

    /**
     * Get all work orders associated with a project
     * @param {string} projectId - Project ID
     * @param {Object} [params] - Query parameters for filtering work orders
     */
    async getProjectWorkOrders(projectId, params = {}) {
        try {
            const queryString = buildQueryString(params);
            return await apiClient.request(
                `${API_CONFIG.ENDPOINTS.PROJECT.WORK_ORDERS(projectId)}${queryString}`
            );
        } catch (error) {
            console.error('Error fetching project work orders:', error);
            throw error;
        }
    },

    /**
     * Add a worker to a project
     * @param {string} projectId - Project ID
     * @param {string} workerId - Worker ID
     */
    async addWorker(projectId, workerId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.ADD_WORKER(projectId),
                {
                    method: 'POST',
                    body: JSON.stringify({ worker_id: workerId })
                }
            );
        } catch (error) {
            console.error('Error adding worker to project:', error);
            throw error;
        }
    },

    /**
     * Get project statistics
     * @param {string} projectId - Project ID
     */
    async getProjectStats(projectId) {
        try {
            const [workOrders, works] = await Promise.all([
                this.getProjectWorkOrders(projectId),
                this.getProjectWorks(projectId)
            ]);

            return {
                totalWorkOrders: workOrders.length,
                activeWorkOrders: workOrders.filter(wo => wo.status === 'IN_PROGRESS').length,
                completedWorkOrders: workOrders.filter(wo => wo.status === 'COMPLETED').length,
                totalWorks: works.length
            };
        } catch (error) {
            console.error('Error fetching project statistics:', error);
            throw error;
        }
    }
};