<script>
  import ImageProcessor from './analyzers/ImageProcessor.svelte';
  import VideoProcessor from './analyzers/VideoProcessor.svelte';
  import { annotations } from './store/vision-store';

  export let media = null;
  export let mediaType = null;
</script>

{#if mediaType === 'photo' || mediaType === 'image'}
  <ImageProcessor media={{ media, type: mediaType }} />
{:else if mediaType === 'video'}
  <VideoProcessor media={{ media, type: mediaType }} />
{/if}