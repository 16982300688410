// stores/auth.js
import { writable } from 'svelte/store';
import { authService } from '../services/api';

function createAuthStore() {
    const { subscribe, set, update } = writable({
        isAuthenticated: false,
        user: null,
        isLoading: false,
        error: null,
        initialized: false
    });

    return {
        subscribe,
        
        async login(username, password) {
            update(state => ({ ...state, isLoading: true, error: null }));
            
            try {
                const response = await authService.login(username, password);
                console.log('Auth response:', response);
                
                if (response?.access) {
                    update(state => ({
                        ...state,
                        isAuthenticated: true,
                        user: { username, ...response },
                        isLoading: false,
                        initialized: true
                    }));
                    return response;
                }
            } catch (error) {
                console.error('Auth store login error:', error);
                update(state => ({
                    ...state,
                    isAuthenticated: false,
                    user: null,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        async register(username, password) {
            update(state => ({ ...state, isLoading: true, error: null }));
            
            try {
                const registerResponse = await authService.register(username, password);
                
                if (registerResponse?.success) {
                    // Try auto-login after successful registration
                    const loginResponse = await this.login(username, password);
                    return { success: true, access: loginResponse.access };
                }
                throw new Error(registerResponse.error || 'Registration failed');
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message || 'Registration failed',
                    isLoading: false
                }));
                throw error;
            }
        },

        async logout() {
            update(state => ({ ...state, isLoading: true, error: null }));
            
            try {
                await authService.logout();
                set({
                    isAuthenticated: false,
                    user: null,
                    isLoading: false,
                    error: null,
                    initialized: true
                });
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message || 'Logout failed',
                    isLoading: false
                }));
                throw error;
            }
        },

        checkAuth() {
            const authState = authService.checkAuth();
            
            update(state => ({
                ...state,
                isAuthenticated: authState.isAuthenticated,
                user: authState.username ? { username: authState.username } : null,
                initialized: true
            }));
            
            return authState;
        },

        clearError() {
            update(state => ({ ...state, error: null }));
        },

        reset() {
            set({
                isAuthenticated: false,
                user: null,
                isLoading: false,
                error: null,
                initialized: false
            });
        }
    };
}

export const auth = createAuthStore();