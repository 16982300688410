<script>
  export let title = '';
  export let message = '';
  export let onConfirm;
  export let onCancel;
</script>

<div class="modal-overlay" on:click={onCancel}>
  <div class="modal-content" on:click|stopPropagation>
    <h2>{title}</h2>
    <p>{message}</p>
    <div class="button-group">
      <button class="cancel-btn" on:click={onCancel}>Cancel</button>
      <button class="confirm-btn" on:click={onConfirm}>Confirm</button>
    </div>
  </div>
</div>

<style>
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
  }

  h2 {
    margin-top: 0;
    color: #2c3e50;
  }

  p {
    margin: 1rem 0;
    color: #4a5568;
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
  }

  button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
  }

  .cancel-btn {
    background: #e2e8f0;
    color: #4a5568;
  }

  .confirm-btn {
    background: #ef4444;
    color: white;
  }

  .confirm-btn:hover {
    background: #dc2626;
  }
</style>
