<!-- src/components/workers/WorkerForm.svelte -->
<script>
    import { createEventDispatcher } from 'svelte';
    import { worker } from '../../stores/worker';

    const dispatch = createEventDispatcher();

    let formData = {
        name: '',
        phone: '',
        email: '',
        status: 'AVAILABLE'
    };

    let isLoading = false;
    let error = null;

    // Basic validation
    $: isValid = formData.name.trim() && (formData.phone.trim() || formData.email.trim());

    // Phone number formatting for display
    function formatDisplayPhone(input) {
        if (!input) return '';
        const numbers = input.replace(/\D/g, '');
        if (numbers.length <= 3) return numbers;
        if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
        return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
    }

    // Format phone for API
    function formatApiPhone(phone) {
        if (!phone) return null;
        const numbers = phone.replace(/\D/g, '');
        if (numbers.length !== 10) return null;
        return `+1${numbers}`; // Assuming US numbers for now
    }

    function handlePhoneInput(e) {
        formData.phone = formatDisplayPhone(e.target.value);
    }

    async function handleSubmit() {
        if (!isValid) return;

        isLoading = true;
        error = null;

        try {
            // Format data for API
            const workerData = {
                name: formData.name.trim(),
                phone: formatApiPhone(formData.phone), 
                email: formData.email.trim() || null,
                status: formData.status
            };

            console.log('Sending worker data:', workerData);
            const newWorker = await worker.createWorker(workerData);
            dispatch('created', { worker: newWorker });
        } catch (err) {
            console.error('Failed to create worker:', err);
            error = err.message || 'Failed to create worker';
        } finally {
            isLoading = false;
        }
    }
</script>

<!-- Rest of the template stays the same -->

<div class="worker-form">
    <!-- Header -->
    <div class="form-header">
        <button 
            type="button" 
            class="back-btn" 
            on:click={() => dispatch('close')}
        >
            ←
        </button>
        <h2>Add New Worker</h2>
        <button
            type="submit"
            class="save-btn"
            disabled={!isValid || isLoading}
            on:click={handleSubmit}
        >
            {isLoading ? 'Adding...' : 'Save'}
        </button>
    </div>

    <!-- Error Message -->
    {#if error}
        <div class="error-message" role="alert">
            {error}
        </div>
    {/if}

    <!-- Form -->
    <form 
        on:submit|preventDefault={handleSubmit}
        class="form-content"
    >
        <div class="form-group">
            <label for="name">Name <span class="required">*</span></label>
            <input
                type="text"
                id="name"
                bind:value={formData.name}
                placeholder="Enter worker's name"
                required
            />
        </div>

        <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
                type="tel"
                id="phone"
                value={formData.phone}
                on:input={handlePhoneInput}
                placeholder="(555) 555-5555"
                inputmode="numeric"
            />
            <small class="help-text">Enter 10-digit US phone number</small>
        </div>

        <div class="form-group">
            <label for="email">Email</label>
            <input
                type="email"
                id="email"
                bind:value={formData.email}
                placeholder="worker@example.com"
                inputmode="email"
            />
        </div>

        <div class="form-group">
            <label for="status">Status</label>
            <select 
                id="status" 
                bind:value={formData.status}
            >
                <option value="AVAILABLE">Available</option>
                <option value="BUSY">Busy</option>
                <option value="ON_LEAVE">On Leave</option>
                <option value="INACTIVE">Inactive</option>
            </select>
        </div>

        <p class="note">
            * Name and either phone or email required
        </p>
    </form>
</div>

<style>
    .worker-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: white;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }

    .form-header h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0;
    }

    .back-btn, 
    .save-btn {
        height: 48px;
        min-width: 48px;
        border: none;
        border-radius: 0.5rem;
        font-size: 1.125rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }

    .back-btn {
        background: transparent;
        font-size: 1.5rem;
    }

    .back-btn:hover {
        background: #f3f4f6;
    }

    .save-btn {
        background: #2563eb;
        color: white;
    }

    .save-btn:disabled {
        background: #93c5fd;
        cursor: not-allowed;
    }

    .form-content {
        flex: 1;
        padding: 1rem;
        overflow-y: auto;
    }

    .form-group {
        margin-bottom: 1.5rem;
    }

    label {
        display: block;
        font-weight: 500;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .required {
        color: #dc2626;
    }

    input,
    select {
        width: 100%;
        height: 48px;
        padding: 0 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        font-size: 1rem;
        background: white;
    }

    input:focus,
    select:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }

    select {
        padding-right: 2.5rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 1.5em 1.5em;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .error-message {
        margin: 1rem;
        padding: 1rem;
        background: #fee2e2;
        color: #dc2626;
        border-radius: 0.5rem;
        font-size: 0.875rem;
    }

    .help-text {
        display: block;
        font-size: 0.75rem;
        color: #6b7280;
        margin-top: 0.25rem;
    }

    .note {
        font-size: 0.875rem;
        color: #6b7280;
        margin-top: 2rem;
    }

    /* Prevent iOS zoom on focus */
    @media screen and (max-width: 640px) {
        input,
        select {
            font-size: 16px;
        }
    }
</style>