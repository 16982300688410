// src/stores/work-order.js
import { workOrderService } from '../services/api';
import { createBaseStore } from './base-store';

const workOrderStore = createBaseStore({
    name: 'work-order',
    cacheTtl: 2 * 60 * 1000, // 2 minutes - shorter TTL due to more frequent updates
    loadFn: filters => workOrderService.getFilteredWorkOrders(filters),
    addFn: data => workOrderService.createWorkOrder(data),
    updateFn: (id, data) => workOrderService.updateWorkOrder(id, data),
    deleteFn: id => workOrderService.deleteWorkOrder(id)
});

workOrderStore.completeWork = async (workOrderId) => {
    try {
        const result = await workOrderService.completeWork(workOrderId);
        
        // Update store with the returned data
        await workOrderStore.update(workOrderId, result);
        return result;
    } catch (error) {
        console.error('Error completing work order:', error);
        throw error;
    }
};

// Add specialized methods
workOrderStore.assignWorker = async (workOrderId, workerId, role = 'PRIMARY') => {
    const state = workOrderStore.getState();
    
    try {
        const result = await workOrderService.assignWorker(workOrderId, workerId, role);
        
        // Optimistic update
        const updatedWorkOrder = {
            ...state.items.find(wo => wo.id === workOrderId),
            assigned_workers: [
                ...(state.items.find(wo => wo.id === workOrderId)?.assigned_workers || []),
                { worker_id: workerId, role }
            ]
        };

        workOrderStore.update(workOrderId, updatedWorkOrder);
        return result;
    } catch (error) {
        console.error('Failed to assign worker:', error);
        // Revert optimistic update
        await workOrderStore.load({}, true);
        throw error;
    }
};

export { workOrderStore };