/**
 * client-service.js
 * Handles all client-related API interactions
 */

import { API_CONFIG, buildQueryString } from './config';
import { apiClient } from './client';

export const clientService = {
    async getClients(params = {}) {
        const queryString = buildQueryString(params);
        const url = `${API_CONFIG.ENDPOINTS.CLIENT.LIST}${queryString}`;
        return await apiClient.request(url);
    },

    async getClientById(clientId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.CLIENT.UPDATE(clientId),
                { method: 'GET' }
            );
        } catch (error) {
            console.error('Error fetching client:', error);
            throw error;
        }
    },

    async createClient(clientData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.CLIENT.CREATE,
                {
                    method: 'POST',
                    body: JSON.stringify(clientData)
                }
            );
        } catch (error) {
            console.error('Error creating client:', error);
            throw error;
        }
    },

    async updateClient(clientId, clientData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.CLIENT.UPDATE(clientId),
                {
                    method: 'PUT',
                    body: JSON.stringify(clientData)
                }
            );
        } catch (error) {
            console.error('Error updating client:', error);
            throw error;
        }
    },

    async patchClient(clientId, partialData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.CLIENT.UPDATE(clientId),
                {
                    method: 'PATCH',
                    body: JSON.stringify(partialData)
                }
            );
        } catch (error) {
            console.error('Error patching client:', error);
            throw error;
        }
    },

    async deleteClient(clientId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.CLIENT.DELETE(clientId),
                {
                    method: 'DELETE'
                }
            );
        } catch (error) {
            console.error('Error deleting client:', error);
            throw error;
        }
    },

    // Utility method for checking if response contains error
    _handleError(response) {
        if (response.error) {
            throw new Error(response.error);
        }
        return response;
    }
};