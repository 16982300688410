// src/stores/worker.js
import { writable, derived } from 'svelte/store';
import { workerService } from '../services/api';

const createWorkerStore = () => {
    const initialState = {
        workers: [],
        currentWorker: null,
        workerSchedules: new Map(), // Cache worker schedules
        workerProjects: new Map(),  // Cache worker projects
        workOrders: new Map(),      // Cache work orders
        isLoading: false,
        error: null
    };

    const { subscribe, set, update } = writable(initialState);

    return {
        subscribe,
        reset: () => set(initialState),

        loadWorkers: async (filters = {}) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const workers = await workerService.getAvailableWorkers(filters);
                update(state => ({
                    ...state,
                    workers,
                    isLoading: false
                }));
                return workers;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        createWorker: async (workerData) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const newWorker = await workerService.createWorker(workerData);
                update(state => ({
                    ...state,
                    workers: [...state.workers, newWorker],
                    isLoading: false
                }));
                return newWorker;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        updateWorker: async (workerId, workerData) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const updatedWorker = await workerService.updateWorker(workerId, workerData);
                update(state => ({
                    ...state,
                    workers: state.workers.map(w => 
                        w.id === workerId ? updatedWorker : w
                    ),
                    currentWorker: state.currentWorker?.id === workerId ? updatedWorker : state.currentWorker,
                    isLoading: false
                }));
                return updatedWorker;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        updateWorkerStatus: async (workerId, status) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                await workerService.updateWorkerStatus(workerId, status);
                update(state => ({
                    ...state,
                    workers: state.workers.map(w => 
                        w.id === workerId ? { ...w, status } : w
                    ),
                    currentWorker: state.currentWorker?.id === workerId 
                        ? { ...state.currentWorker, status }
                        : state.currentWorker,
                    isLoading: false
                }));
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        // New methods to match backend capabilities
        loadWorkerSchedule: async (workerId, params = {}) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const schedule = await workerService.getWorkerSchedule(workerId, params);
                update(state => ({
                    ...state,
                    workerSchedules: new Map(state.workerSchedules).set(workerId, schedule),
                    isLoading: false
                }));
                return schedule;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        loadWorkerProjects: async (workerId) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const projects = await workerService.getWorkerProjects(workerId);
                update(state => ({
                    ...state,
                    workerProjects: new Map(state.workerProjects).set(workerId, projects),
                    isLoading: false
                }));
                return projects;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        loadCurrentWorkOrders: async (workerId) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const workOrders = await workerService.getCurrentWorkOrders(workerId);
                update(state => ({
                    ...state,
                    workOrders: new Map(state.workOrders).set(workerId, workOrders),
                    isLoading: false
                }));
                return workOrders;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        getWorkerStats: async (workerId) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const stats = await workerService.getWorkerStats(workerId);
                update(state => ({ ...state, isLoading: false }));
                return stats;
            } catch (error) {
                update(state => ({
                    ...state,
                    error: error.message,
                    isLoading: false
                }));
                throw error;
            }
        },

        setCurrentWorker: (worker) => {
            update(state => ({ ...state, currentWorker: worker }));
        },

        clearError: () => {
            update(state => ({ ...state, error: null }));
        }
    };
};

export const worker = createWorkerStore();

// Derived stores matching backend functionality
export const availableWorkers = derived(
    worker,
    $worker => $worker.workers.filter(w => w.status === 'AVAILABLE')
);

export const workersBySkill = derived(
    worker,
    $worker => (skill) => 
        $worker.workers.filter(w => 
            w.skills && Object.keys(w.skills).includes(skill)
        )
);

export const workerSchedule = derived(
    worker,
    $worker => (workerId) => $worker.workerSchedules.get(workerId)
);

export const workerProjects = derived(
    worker,
    $worker => (workerId) => $worker.workerProjects.get(workerId)
);

export const workerWorkOrders = derived(
    worker,
    $worker => (workerId) => $worker.workOrders.get(workerId)
);