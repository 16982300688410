<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import Login from './Login.svelte';
  import Register from './Register.svelte';

  const dispatch = createEventDispatcher();
  let showRegister = false;

  /**
   * Handles successful login or registration.
   * Dispatches a 'success' event.
   */
  function handleSuccess() {
    try {
      console.log('[Log] handleSuccess triggered');
      dispatch('success');
    } catch (error) {
      console.error('Error dispatching success event:', error);
    }
  }

  /**
   * Toggles between Login and Register forms.
   */
  function toggleMode() {
    try {
      showRegister = !showRegister;
      console.log(`[Log] Toggled to ${showRegister ? 'Register' : 'Login'} mode`);
    } catch (error) {
      console.error('Error toggling auth mode:', error);
    }
  }

  // Log when the component is mounted
  onMount(() => {
    try {
      console.log('[Log] AuthModal mounted successfully (AuthModal.svelte)');
    } catch (error) {
      console.error('Error during onMount:', error);
    }
  });
</script>

<!-- 
  Layout:
  - Fixed full-screen layout to cover the entire viewport.
  - Left section: media placeholder.
  - Right section: authentication (Login/Register).
-->
<div class="auth-layout">
  <!-- Media Side -->
  <aside class="media-side">
    <!-- Media Placeholder: Replace with actual media (images, slideshow, video) -->
    <div class="media-placeholder">
      <p>Your product images, slideshow, or video goes here.</p>
      <!-- Example: Replace with a <video> or an image carousel -->
    </div>
  </aside>

  <!-- Auth Side -->
  <section class="auth-side">
    {#if showRegister}
      <Register on:success={handleSuccess} />
      <button class="toggle-auth" on:click={toggleMode}>
        Already have an account? Log in
      </button>
    {:else}
      <Login on:success={handleSuccess} />
      <button class="toggle-auth" on:click={toggleMode}>
        Need an account? Register
      </button>
    {/if}
  </section>
</div>

<style>
  /* 
    ================
    Fixed Full-Screen Layout
    ================
  */
  .auth-layout {
    position: fixed; /* Ensure it covers the viewport independently */
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    background-color: white; /* Optional: Set a background color */
    z-index: 1000; /* Ensure it overlays other content */
    overflow: hidden; /* Prevent scrollbars */
    font-family: Arial, sans-serif;
  }

  /* Media Side */
  .media-side {
    flex: 1 1 55%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box; /* Include padding in size calculations */
  }

  .media-placeholder {
    text-align: center;
    color: #555;
    max-width: 500px;
    /* Ensure no circular styles are applied */
  }

  /* Auth Side */
  .auth-side {
    flex: 1 1 45%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    box-sizing: border-box; /* Include padding in size calculations */
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  }

  .toggle-auth {
    margin-top: 1.5rem;
    background: none;
    border: none;
    color: #3498db;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
    transition: color 0.2s;
  }

  .toggle-auth:hover {
    color: #2980b9;
  }

  /* Responsive Layout */
  @media (max-width: 800px) {
    .auth-layout {
      flex-direction: column;
    }

    .media-side {
      display: none; /* Hide media side on smaller screens */
    }

    .auth-side {
      flex: 1 1 auto;
      width: 100%;
      box-shadow: none;
      padding: 2rem 1rem;
    }
  }
</style>
