// services/api/auth.js
import { API_CONFIG } from './config';
import { apiClient } from './client';

const TOKEN_KEYS = {
    ACCESS: 'accessToken',
    REFRESH: 'refreshToken',
    USERNAME: 'username'
};

export const authService = {
    _clearTokens() {
        Object.values(TOKEN_KEYS).forEach(key => localStorage.removeItem(key));
    },

    _setTokens(tokens, username) {
        if (tokens.access) {
            localStorage.setItem(TOKEN_KEYS.ACCESS, tokens.access);
            localStorage.setItem(TOKEN_KEYS.REFRESH, tokens.refresh);
            localStorage.setItem(TOKEN_KEYS.USERNAME, username);
        }
    },

    async login(username, password) {
        try {
            const data = await apiClient.request(
                API_CONFIG.ENDPOINTS.AUTH.LOGIN,  // Changed from TOKEN to LOGIN
                {
                    method: 'POST',
                    body: JSON.stringify({ username, password })
                }
            );
            if (data.access) {
                this._setTokens(data, username);
            }
            return data;
        } catch (error) {
            this._clearTokens();
            throw error;
        }
    },

    async register(username, password) {
        const data = await apiClient.request(
            API_CONFIG.ENDPOINTS.AUTH.REGISTER,
            {
                method: 'POST',
                body: JSON.stringify({ username, password })
            }
        );
        return data;
    },

    async logout() {
        try {
            const refreshToken = localStorage.getItem(TOKEN_KEYS.REFRESH);
            if (refreshToken) {
                await apiClient.request(
                    API_CONFIG.ENDPOINTS.AUTH.LOGOUT,
                    {
                        method: 'POST',
                        body: JSON.stringify({ refresh: refreshToken })
                    }
                );
            }
        } finally {
            this._clearTokens();
        }
    },

    checkAuth() {
        const token = localStorage.getItem(TOKEN_KEYS.ACCESS);
        const username = localStorage.getItem(TOKEN_KEYS.USERNAME);
        return {
            isAuthenticated: !!token,
            username
        };
    }
};