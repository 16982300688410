// stores/base-store.js
import { writable, get } from 'svelte/store';
import { StoreCache } from './cache';

export function createBaseStore(options = {}) {
    const cache = new StoreCache(options.cacheTtl || 5 * 60 * 1000); // Default 5min TTL
    const pendingRequests = new Map();
    
    const { subscribe, set, update } = writable({
        items: [],
        loading: false,
        error: null,
        selected: null,
        lastUpdated: null
    });

    // Request debouncing
    const debouncedRequests = new Map();
    
    function debounce(key, fn, delay = 300) {
        if (debouncedRequests.has(key)) {
            clearTimeout(debouncedRequests.get(key));
        }
        
        const timeout = setTimeout(() => {
            fn();
            debouncedRequests.delete(key);
        }, delay);
        
        debouncedRequests.set(key, timeout);
    }

    // Batch update handling
    let batchTimeout;
    const batchedUpdates = new Set();
    
    function processBatchedUpdates() {
        if (batchedUpdates.size === 0) return;
        
        update(state => {
            const newState = { ...state };
            batchedUpdates.forEach(updateFn => updateFn(newState));
            batchedUpdates.clear();
            return newState;
        });
    }

    function queueUpdate(updateFn) {
        batchedUpdates.add(updateFn);
        cancelAnimationFrame(batchTimeout);
        batchTimeout = requestAnimationFrame(processBatchedUpdates);
    }

    // Optimistic updates handling
    const optimisticUpdates = new Map();

    function handleOptimisticUpdate(id, updateFn, revertFn) {
        // Apply immediate update
        update(state => {
            updateFn(state);
            return state;
        });
        
        optimisticUpdates.set(id, { updateFn, revertFn });
        
        return {
            succeed: () => {
                optimisticUpdates.delete(id);
            },
            fail: () => {
                const update = optimisticUpdates.get(id);
                if (update) {
                    update.revertFn();
                    optimisticUpdates.delete(id);
                }
            }
        };
    }

    // Relationship tracking for cache invalidation
    const relationships = new Map();
    
    function trackRelationship(parentType, parentId, childType, childId) {
        const key = `${parentType}:${parentId}`;
        if (!relationships.has(key)) {
            relationships.set(key, new Set());
        }
        relationships.get(key).add(`${childType}:${childId}`);
        return this;
    }
    
    function invalidateRelated(type, id) {
        const key = `${type}:${id}`;
        if (relationships.has(key)) {
            relationships.get(key).forEach(relatedKey => {
                const [relatedType, relatedId] = relatedKey.split(':');
                cache.invalidate(`${relatedType}-${relatedId}`);
            });
        }
        return this;
    }

    return {
        subscribe,

        // Core CRUD operations
        async load(filters = {}, forceRefresh = false) {
            const cacheKey = JSON.stringify(filters);
            
            update(state => ({ ...state, loading: true }));
            
            try {
                if (!forceRefresh) {
                    const cached = cache.get(cacheKey);
                    if (cached) {
                        update(state => ({
                            ...state,
                            items: cached,
                            loading: false
                        }));
                        return cached;
                    }
                }

                if (pendingRequests.has(cacheKey)) {
                    const result = await pendingRequests.get(cacheKey);
                    update(state => ({
                        ...state,
                        items: result,
                        loading: false
                    }));
                    return result;
                }

                const request = options.loadFn(filters)
                    .then(data => {
                        cache.set(cacheKey, data);
                        update(state => ({
                            ...state,
                            items: data,
                            loading: false,
                            lastUpdated: Date.now()
                        }));
                        pendingRequests.delete(cacheKey);
                        return data;
                    })
                    .catch(error => {
                        console.error(`Failed to load ${options.name}:`, error);
                        update(state => ({
                            ...state,
                            error: error.message,
                            loading: false
                        }));
                        pendingRequests.delete(cacheKey);
                        throw error;
                    });

                pendingRequests.set(cacheKey, request);
                return request;
            } catch (error) {
                console.error(`Failed to load ${options.name}:`, error);
                update(state => ({
                    ...state,
                    error: error.message,
                    loading: false
                }));
                throw error;
            }
        },

        async add(data) {
            update(state => ({ ...state, loading: true }));
            try {
                const result = await options.addFn(data);
                
                queueUpdate(state => {
                    state.items = [...state.items, result];
                    state.loading = false;
                    state.lastUpdated = Date.now();
                });

                cache.clear(); // Invalidate cache after mutation
                return result;
            } catch (error) {
                console.error(`Failed to add ${options.name}:`, error);
                update(state => ({
                    ...state,
                    error: error.message,
                    loading: false
                }));
                throw error;
            }
        },

        async update(id, data) {
            const optimistic = handleOptimisticUpdate(
                id,
                state => {
                    state.items = state.items.map(item =>
                        item.id === id ? { ...item, ...data } : item
                    );
                    if (state.selected?.id === id) {
                        state.selected = { ...state.selected, ...data };
                    }
                },
                state => {
                    // Revert logic in case of failure
                    this.load({}, true);
                }
            );

            try {
                const result = await options.updateFn(id, data);
                optimistic.succeed();
                cache.clear();
                return result;
            } catch (error) {
                console.error(`Failed to update ${options.name}:`, error);
                optimistic.fail();
                throw error;
            }
        },

        async delete(id) {
            const optimistic = handleOptimisticUpdate(
                id,
                state => {
                    state.items = state.items.filter(item => item.id !== id);
                    if (state.selected?.id === id) {
                        state.selected = null;
                    }
                },
                state => {
                    // Revert logic
                    this.load({}, true);
                }
            );

            try {
                await options.deleteFn(id);
                optimistic.succeed();
                cache.clear();
            } catch (error) {
                console.error(`Failed to delete ${options.name}:`, error);
                optimistic.fail();
                throw error;
            }
        },

        // Selection handling
        setSelected(id) {
            queueUpdate(state => {
                state.selected = state.items.find(item => item.id === id);
            });
            return this;
        },

        clearSelected() {
            queueUpdate(state => {
                state.selected = null;
            });
            return this;
        },

        // Cache management
        clearCache() {
            cache.clear();
            return this;
        },

        // Error handling
        clearError() {
            update(state => ({ ...state, error: null }));
            return this;
        },

        // Relationship management
        trackRelationship,
        invalidateRelated,

        // State access
        getState() {
            return get({ subscribe });
        },

        // Optimistic updates
        optimisticUpdate: handleOptimisticUpdate,

        // Request management
        debounceRequest(key, fn, delay) {
            debounce(key, fn, delay);
            return this;
        }
    };
}