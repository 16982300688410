<script lang="ts">
  import { annotations } from '../store/vision-store';
  import { visionApi } from '../../../../../services/api/vision';

  export let media: { media: string; type: 'video' };

  type ProcessingState = 'idle' | 'processing' | 'success' | 'error';
  let processingState: ProcessingState = 'idle';
  let errorMessage: string | null = null;

  async function processVideo(videoData: string) {
    const processingStart = performance.now();
    processingState = 'processing';
    errorMessage = null;

    try {
      console.log('[VideoProcessor] Starting vision analysis for video', {
        mediaType: 'video',
        size: videoData.length
      });

      // If your backend has a dedicated endpoint:
      // const visionResults = await visionApi.analyzeVideo(videoData.split(',')[1]);
      // Or call analyzeImage multiple times if you extract frames
      // For now, let's assume a single "analyzeVideo" call:
      const base64Data = videoData.split(',')[1];
      const visionResults = await visionApi.analyzeVideo(base64Data);

      console.log('[VideoProcessor] Vision results received', {
        duration: ((performance.now() - processingStart) / 1000).toFixed(2) + 's',
        annotationCount: visionResults.annotations?.length,
        metadata: visionResults.metadata
      });

      if (visionResults.annotations?.length) {
        processingState = 'success';
        annotations.set(visionResults.annotations);
      } else {
        throw new Error('No annotations in response');
      }
    } catch (error) {
      processingState = 'error';
      errorMessage = error.message || 'Failed to analyze video';

      console.error('[VideoProcessor] Vision processing failed:', {
        error: error.message,
        processingTime: ((performance.now() - processingStart) / 1000).toFixed(2) + 's',
        state: processingState
      });

      // Clear any existing annotations on error
      annotations.set([]);

      // Emit an error event if you want a parent listener
      const errorEvent = new CustomEvent('vision-error', {
        detail: {
          message: errorMessage,
          timestamp: Date.now()
        }
      });
      dispatchEvent(errorEvent);
    }
  }

  // Just like ImageProcessor, we only trigger once if idle
  $: if (media && media.type === 'video' && processingState === 'idle') {
    console.log('[VideoProcessor] New media received, about to process...');
    processVideo(media.media);
  }
</script>

{#if processingState === 'processing'}
  <div class="processing-indicator">
    Analyzing video...
  </div>
{:else if processingState === 'error'}
  <div class="error-message">
    {errorMessage}
  </div>
{:else if processingState === 'success'}
  <div class="success-message">
    Video analysis complete!
  </div>
{/if}
