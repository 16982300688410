// stores/project.js
import { derived } from 'svelte/store';
import { createBaseStore } from './base-store';
import { projectService } from '../services/api';
import { workOrderStore } from './work-order';
import { worker } from './worker'; // Fixed import to match actual export name

const projectStore = createBaseStore({
    name: 'project',
    cacheTtl: 5 * 60 * 1000, // 5 minutes
    loadFn: filters => projectService.getProjects(filters),
    addFn: data => projectService.createProject(data),
    updateFn: (id, data) => projectService.updateProject(id, data),
    deleteFn: id => projectService.deleteProject(id)
});

// Add specialized methods
projectStore.getProjectStats = async (projectId) => {
    const stats = await projectService.getProjectStats(projectId);
    return stats;
};

// Add derived stores for common queries
export const activeProjects = derived(
    projectStore,
    $projectStore => $projectStore.items.filter(p => p.status === 'IN_PROGRESS')
);

export const projectWorkOrders = derived(
    [projectStore, workOrderStore],
    ([$projectStore, $workOrderStore]) => 
        (projectId) => $workOrderStore.items.filter(wo => wo.project === projectId)
);

export const projectWorkers = derived(
    [projectStore, worker],
    ([$projectStore, $worker]) => 
        (projectId) => {
            const project = $projectStore.items.find(p => p.id === projectId);
            return project ? $worker.workers.filter(w => 
                project.workers.includes(w.id)
            ) : [];
        }
);

export { projectStore };