<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { auth } from '../../stores/auth';
  import { portfolioService } from '../../services/api/portfolio';
  import './styles/create-work.css';
  import MediaCapture from './media/capture/MediaCapture.svelte';
  import MediaPreview from './media/preview/MediaPreview.svelte';
  import Editor from './description/editor/Editor.svelte';
  import AIControls from './description/editor/AIControls.svelte';
  import AnnotationReviewer from './description/ai/AnnotationReviewer.svelte';
  import AuthModal from '../auth/AuthModal.svelte';
  
  let capturedMedia = null;
  let mediaType = null;
  let description = '';
  let isSubmitting = false;
  let showAuthModal = false;
  let pendingSubmission = null;

  // Handle media capture
  function handleCapture(event) {
    const { media, type } = event.detail;
    capturedMedia = media;
    mediaType = type;
  }

  function handleClear() {
    capturedMedia = null;
    mediaType = null;
  }

  function handleError(event) {
    console.error('[CreateWork] Media capture error:', event.detail);
  }

  function handleDescriptionChange(event) {
    description = event.detail.text;
  }

  async function handleSubmit() {
    if (!$auth.isAuthenticated) {
      // Save current work data before showing auth modal
      pendingSubmission = {
        media: capturedMedia,
        mediaType,
        description
      };
      showAuthModal = true;
      return;
    }

    try {
      isSubmitting = true;
      const formData = new FormData();
      
      // Add media file
      if (capturedMedia) {
        const blob = await fetch(capturedMedia).then(r => r.blob());
        formData.append('media', blob, `capture.${mediaType}`);
      }
      
      // Add description
      formData.append('description', description);

      const response = await portfolioService.createWork(formData);
      
      // Navigate to portfolio view after successful submission
      window.location.href = '/portfolio';
    } catch (error) {
      console.error('Failed to submit work:', error);
      // Handle error appropriately
    } finally {
      isSubmitting = false;
    }
  }

  async function handleAuthSuccess() {
    showAuthModal = false;
    if (pendingSubmission) {
      // Restore saved work data and submit
      capturedMedia = pendingSubmission.media;
      mediaType = pendingSubmission.mediaType;
      description = pendingSubmission.description;
      pendingSubmission = null;
      await handleSubmit();
    }
  }

  onMount(() => {
    console.log('[CreateWork] Component mounted');
  });
</script>

<div class="create-work-container">
  <div class="create-work-content">
    <!-- Single Card for Media + Description -->
    <div class="content-card">
      <!-- Media Section -->
      <div class="content-section media-section">
        {#if !capturedMedia}
          <MediaCapture
            keepCameraActive={true}
            on:capture={handleCapture}
            on:error={handleError}
          />
        {:else}
          <MediaPreview
            media={capturedMedia}
            type={mediaType}
            on:clear={handleClear}
          />
        {/if}
      </div>

      <!-- Subtle Divider -->
      <div class="section-divider"></div>

      <!-- Description Section -->
      <div class="content-section">
        <Editor on:change={handleDescriptionChange} />
      </div>
    </div>

    <!-- Submit Button -->
    <button
      class="submit-button"
      disabled={isSubmitting || (!capturedMedia && !description)}
      on:click={handleSubmit}
    >
      {isSubmitting ? 'Submitting...' : 'Submit Work'}
    </button>
  </div>
</div>

{#if showAuthModal}
  <AuthModal 
    on:close={() => showAuthModal = false}
    on:success={handleAuthSuccess}
  />
{/if}
