<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import { workOrderStore } from '../../stores/work-order';
    import { projectStore } from '../../stores/project';
    import { worker } from '../../stores/worker';
    import { fade, slide } from 'svelte/transition';
    import { cubicOut } from 'svelte/easing';

    // Props to distinguish between "Create" vs. "Edit"
    export let isEdit = false;
    export let existingWorkOrder = null;

    const dispatch = createEventDispatcher();

    // Mobile Detection
    const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 640 : false;

    // Form Data
    let formData = {
        title: '',
        description: '',
        project: '',
        priority: 'MEDIUM',
        status: 'IN_PROGRESS',  // Default status
        assigned_workers: []
    };

    let error = null;
    let loading = false;
    let projects = [];
    let availableWorkers = [];
    let submitAttempted = false;

    // Field Errors
    let fieldErrors = {
        title: '',
        project: ''
    };

    // Drag Handling for Mobile (if implementing as a bottom sheet)
    let startY = 0;
    let currentY = 0;
    let isDragging = false;
    const CLOSE_THRESHOLD = 150;
    let isClosing = false;

    onMount(async () => {
        try {
            await projectStore.load();
            projects = $projectStore.items;

            await worker.loadWorkers();
            availableWorkers = $worker.workers;
        } catch (err) {
            console.error('Error loading projects/workers:', err);
            error = 'Failed to load form data';
        }

        // If editing an existing work order, prefill formData
        if (isEdit && existingWorkOrder) {
            formData.title = existingWorkOrder.title || '';
            formData.description = existingWorkOrder.description || '';
            formData.project = existingWorkOrder.project || '';
            formData.priority = existingWorkOrder.priority || 'MEDIUM';
            formData.status = existingWorkOrder.status || 'IN_PROGRESS';

            // Convert assigned_workers array
            formData.assigned_workers = existingWorkOrder.assigned_workers
                ? existingWorkOrder.assigned_workers.map(aw => ({
                    worker_id: aw.worker_id,
                    role: aw.role
                  }))
                : [];
        }

        // Focus on the first input field
        titleInput?.focus();

        if (isMobile) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            if (isMobile) {
                document.body.style.overflow = '';
            }
        };
    });

    // References
    let titleInput;

    // Reactive Statements for Validation
    $: isValid = validateForm();
    $: sheetStyle = isDragging ? `transform: translateY(${currentY}px)` : '';

    function validateForm() {
        if (!submitAttempted) return true;

        let isFormValid = true;
        fieldErrors = {
            title: '',
            project: ''
        };

        if (!formData.title.trim()) {
            fieldErrors.title = 'Title is required';
            isFormValid = false;
        }

        if (!formData.project) {
            fieldErrors.project = 'Project selection is required';
            isFormValid = false;
        }

        return isFormValid;
    }

    function handleInput() {
        if (submitAttempted) {
            validateForm();
        }
    }

    function toggleWorker(workerId) {
        const index = formData.assigned_workers.findIndex(w => w.worker_id === workerId);
        if (index === -1) {
            formData.assigned_workers = [
                ...formData.assigned_workers,
                { worker_id: workerId, role: 'PRIMARY' }
            ];
        } else {
            formData.assigned_workers = formData.assigned_workers.filter(w => w.worker_id !== workerId);
        }

        if (submitAttempted) {
            validateForm();
        }
    }

    async function handleSubmit() {
        submitAttempted = true;
        if (!isValid) {
            error = 'Please correct the form errors';
            return;
        }

        loading = true;
        error = null;

        try {
            const payload = {
                title: formData.title.trim(),
                description: formData.description.trim() || null,
                project: formData.project,
                priority: formData.priority,
                status: formData.status || 'IN_PROGRESS',
                assigned_workers: formData.assigned_workers
            };

            if (isEdit && existingWorkOrder) {
                await workOrderStore.update(existingWorkOrder.id, payload);
            } else {
                await workOrderStore.add(payload);
            }

            dispatch('saved');
            dispatch('close');
        } catch (err) {
            console.error('Failed to save work order:', err);
            error = err.message || 'Failed to save work order';
        } finally {
            loading = false;
        }
    }

    // Touch Handlers for Mobile
    function handleTouchStart(e) {
        if (isClosing) return;
        startY = e.touches[0].clientY;
        isDragging = true;
    }

    function handleTouchMove(e) {
        if (!isDragging || isClosing) return;
        const delta = e.touches[0].clientY - startY;
        if (delta < 0) return;
        currentY = delta;
        e.preventDefault();
    }

    async function handleTouchEnd() {
        if (!isDragging || isClosing) return;
        
        if (currentY > CLOSE_THRESHOLD) {
            isClosing = true;
            if (window.navigator?.vibrate) {
                window.navigator.vibrate(50);
            }
            handleConfirmClose();
        }
        
        currentY = 0;
        isDragging = false;
    }

    function handleConfirmClose() {
        if (loading) return;

        if (isFormDirty() && !isClosing) {
            if (confirm('Are you sure you want to discard your changes?')) {
                dispatch('close');
            }
        } else {
            dispatch('close'); 
        }
    }

    function isFormDirty() {
        if (isEdit && existingWorkOrder) {
            return (
                formData.title !== (existingWorkOrder.title || '') ||
                formData.description !== (existingWorkOrder.description || '') ||
                formData.project !== (existingWorkOrder.project || '') ||
                formData.priority !== (existingWorkOrder.priority || 'MEDIUM') ||
                formData.status !== (existingWorkOrder.status || 'IN_PROGRESS') ||
                JSON.stringify(formData.assigned_workers) !== JSON.stringify(existingWorkOrder.assigned_workers || [])
            );
        } else {
            return (
                formData.title.trim() !== '' ||
                formData.description.trim() !== '' ||
                formData.project !== '' ||
                formData.priority !== 'MEDIUM' ||
                formData.status !== 'IN_PROGRESS' ||
                formData.assigned_workers.length > 0
            );
        }
    }
</script>

<!-- Modal Overlay -->
<div 
    class="modal-overlay" 
    class:mobile={isMobile}
    on:click={handleConfirmClose}
    transition:fade={{ duration: 200 }}
>
    <!-- Modal Sheet -->
    <div
        class="modal-sheet"
        class:mobile={isMobile}
        style={sheetStyle}
        on:click|stopPropagation
        transition:slide={{
            duration: 300,
            easing: cubicOut,
            axis: 'y'
        }}
        on:touchstart|passive={isMobile ? handleTouchStart : null}
        on:touchmove|passive={isMobile ? handleTouchMove : null}
        on:touchend|passive={isMobile ? handleTouchEnd : null}
    >
        {#if isMobile}
            <div 
                class="drag-handle"
                on:touchstart={handleTouchStart}
                on:touchmove={handleTouchMove}
                on:touchend={handleTouchEnd}
            >
                <div class="handle-bar"></div>
            </div>
        {/if}

        <div class="work-order-form">
            <!-- Header -->
            <div class="form-header">
                <h2>{isEdit ? 'Edit Work Order' : 'Create Work Order'}</h2>
                <!-- Close Button with type="button" -->
                <button 
                    type="button" 
                    class="close-button" 
                    on:click={handleConfirmClose} 
                    aria-label="Close Work Order Form"
                >
                    &times;
                </button>
            </div>

            <!-- Error Message -->
            {#if error}
                <div class="error-message" role="alert" transition:fade>
                    {error}
                </div>
            {/if}

            <!-- Form -->
            <form on:submit|preventDefault={handleSubmit} class="form-content" novalidate>
                <div class="form-group">
                    <label for="title">Title <span class="required">*</span></label>
                    <input
                        type="text"
                        id="title"
                        bind:value={formData.title}
                        placeholder="Enter work order title"
                        class:error={fieldErrors.title}
                        on:input={handleInput}
                        required
                        bind:this={titleInput}
                        aria-invalid={!!fieldErrors.title}
                        aria-describedby={fieldErrors.title ? 'title-error' : undefined}
                    />
                    {#if fieldErrors.title}
                        <span class="field-error" id="title-error" transition:fade>
                            {fieldErrors.title}
                        </span>
                    {/if}
                </div>

                <div class="form-group">
                    <label for="project">Project <span class="required">*</span></label>
                    <select 
                        id="project" 
                        bind:value={formData.project}
                        class:error={fieldErrors.project}
                        on:change={handleInput}
                        required
                        aria-invalid={!!fieldErrors.project}
                        aria-describedby={fieldErrors.project ? 'project-error' : undefined}
                    >
                        <option value="">Select a project</option>
                        {#each projects as p}
                            <option value={p.id}>{p.title}</option>
                        {/each}
                    </select>
                    {#if fieldErrors.project}
                        <span class="field-error" id="project-error" transition:fade>
                            {fieldErrors.project}
                        </span>
                    {/if}
                </div>

                <div class="form-group">
                    <label for="description">Description</label>
                    <textarea
                        id="description"
                        bind:value={formData.description}
                        placeholder="Enter work order description"
                        rows="4"
                        on:input={handleInput}
                        aria-invalid={!!error}
                        aria-describedby={error ? 'description-error' : undefined}
                    ></textarea>
                </div>

                <div class="form-group">
                    <label for="priority">Priority</label>
                    <select id="priority" bind:value={formData.priority} on:change={handleInput}>
                        <option value="LOW">Low</option>
                        <option value="MEDIUM">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="URGENT">Urgent</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="status">Status</label>
                    <select id="status" bind:value={formData.status} on:change={handleInput}>
                        <option value="DRAFT">Draft</option>
                        <option value="IN_PROGRESS">In Progress</option>
                        <option value="ON_HOLD">On Hold</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="CANCELLED">Cancelled</option>
                    </select>
                </div>

                <!-- Assigned Workers -->
                <div class="form-group worker-assignments">
                    <label>Assign Workers</label>
                    <div class="workers-grid">
                        {#each availableWorkers as w}
                            <label class="worker-checkbox">
                                <input
                                    type="checkbox"
                                    checked={formData.assigned_workers.some(a => a.worker_id === w.id)}
                                    on:change={() => toggleWorker(w.id)}
                                    aria-checked={formData.assigned_workers.some(a => a.worker_id === w.id)}
                                    aria-label={`Assign ${w.name}`}
                                />
                                <span class="worker-info">
                                    <span class="worker-name">{w.name}</span>
                                    {#if w.title}
                                        <span class="worker-title">{w.title}</span>
                                    {/if}
                                    <span class="worker-status {w.status.toLowerCase()}">
                                        {w.status}
                                    </span>
                                </span>
                            </label>
                        {/each}
                    </div>
                </div>

                <!-- Form Actions -->
                <div class="form-actions">
                    <!-- Cancel Button with type="button" -->
                    <button 
                        type="button" 
                        class="cancel-button" 
                        on:click={handleConfirmClose}
                        disabled={loading}
                    >
                        Cancel
                    </button>
                    <!-- Submit Button -->
                    <button 
                        type="submit" 
                        class="submit-button" 
                        disabled={!isValid || loading}
                        aria-busy={loading}
                    >
                        {loading
                            ? (isEdit ? 'Updating...' : 'Creating...')
                            : (isEdit ? 'Update Work Order' : 'Create Work Order')
                        }
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<style>
    /* Modal Overlay */
    .modal-overlay {
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
        display: grid;
        place-items: center;
        z-index: 50;
        backdrop-filter: blur(4px);
    }

    .modal-overlay.mobile {
        background: rgba(0, 0, 0, 0.25);
        align-items: flex-end;
        backdrop-filter: blur(8px);
    }

    /* Modal Sheet */
    .modal-sheet {
        background: white;
        width: 100%;
        max-width: 600px;
        max-height: 90vh;
        border-radius: 1rem;
        overflow: hidden;
        position: relative;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }

    .modal-sheet.mobile {
        max-width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 85vh;
        touch-action: none;
    }

    /* Drag Handle for Mobile */
    .drag-handle {
        height: 24px;
        display: grid;
        place-items: center;
        background: white;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        touch-action: none;
    }

    .handle-bar {
        width: 32px;
        height: 4px;
        background: #e5e7eb;
        border-radius: 2px;
    }

    /* Work Order Form Styles */
    .work-order-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: white;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }

    .form-header h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0;
    }

    /* Close Button */
    .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        padding: 0.5rem;
        line-height: 1;
        border-radius: 0.25rem;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .close-button:hover {
        background: #f3f4f6;
    }

    /* Error Message */
    .error-message {
        margin: 1rem;
        padding: 1rem;
        background: #fee2e2;
        color: #dc2626;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        transition: fade;
    }

    /* Form Content */
    .form-content {
        flex: 1;
        padding: 1rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .form-group {
        margin-bottom: 1.5rem;
    }

    .required {
        color: #dc2626;
    }

    input,
    select,
    textarea {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        font-size: 1rem;
        background: white;
        transition: all 0.2s ease;
    }

    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }

    input.error,
    select.error,
    textarea.error {
        border-color: #dc2626;
    }

    .field-error {
        color: #dc2626;
        font-size: 0.75rem;
        margin-top: 0.25rem;
        display: block;
    }

    /* Workers Grid */
    .worker-assignments {
        margin-top: 1rem;
        background: #f8fafc;
        padding: 1rem;
        border-radius: 0.5rem;
    }

    .workers-grid {
        display: grid;
        gap: 0.5rem;
        margin-top: 0.5rem;
    }

    .worker-checkbox {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        cursor: pointer;
        background: white;
        transition: all 0.2s;
    }

    .worker-checkbox:hover {
        border-color: #d1d5db;
        background: #f9fafb;
    }

    .worker-info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }

    .worker-name {
        font-weight: 500;
    }

    .worker-title {
        color: #6b7280;
        font-size: 0.875rem;
    }

    .worker-status {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        border-radius: 9999px;
        font-size: 0.75rem;
        font-weight: 500;
    }

    .worker-status.available { background: #dcfce7; color: #166534; }
    .worker-status.busy { background: #fef3c7; color: #92400e; }
    .worker-status.on_leave { background: #dbeafe; color: #1e40af; }
    .worker-status.inactive { background: #f3f4f6; color: #4b5563; }

    /* Form Actions */
    .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 2rem;
    }

    .cancel-button,
    .submit-button {
        height: 48px;
        min-width: 100px;
        border: none;
        border-radius: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        transition: all 0.2s ease;
    }

    .cancel-button {
        background: #e2e8f0;
        color: #1f2937;
    }

    .cancel-button:hover:not(:disabled) {
        background: #cbd5e1;
    }

    .submit-button {
        background: #2563eb;
        color: white;
    }

    .submit-button:hover:not(:disabled) {
        background: #1d4ed8;
    }

    .submit-button:disabled,
    .cancel-button:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    /* Mobile Optimizations */
    @media screen and (max-width: 640px) {
        .modal-sheet.mobile {
            height: 85vh;
        }

        .form-content {
            padding-bottom: calc(env(safe-area-inset-bottom, 1rem) + 4rem);
        }

        input,
        select,
        textarea {
            font-size: 16px; /* Prevent iOS zoom */
        }

        .workers-grid {
            grid-template-columns: 1fr;
        }

        .worker-checkbox {
            padding: 1rem;
        }

        .close-button {
            padding: 0.5rem;
            font-size: 1.25rem;
        }

        .form-actions {
            flex-direction: column;
            gap: 0.5rem;
        }

        .submit-button,
        .cancel-button {
            width: 100%;
            font-size: 1rem;
        }
    }
</style>
