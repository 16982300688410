// src/services/api/vision.js
import { API_CONFIG } from './config';
import { apiClient } from './client';

class VisionApi {
  async analyzeImage(imageData) {
    try {
      console.log('[VisionApi] Starting image analysis request');
      
      const startTime = performance.now();
      const response = await apiClient.request(
        API_CONFIG.ENDPOINTS.VISION.ANALYZE_IMAGE,
        {
          method: 'POST',
          body: JSON.stringify({
            image: imageData
          })
        }
      );
      const duration = ((performance.now() - startTime) / 1000).toFixed(2);
      console.log(`[VisionApi] Analysis completed in ${duration}s`, {
        annotationCount: response.annotations?.length,
        metadata: response.metadata
      });

      return response;
    } catch (error) {
      console.error('[VisionApi] Analysis failed:', {
        error: error.message,
        status: error.status,
        endpoint: API_CONFIG.ENDPOINTS.VISION.ANALYZE_IMAGE
      });
      throw error;
    }
  }
}

export const visionApi = new VisionApi();