/**
 * portfolio.js 
 * Handles all portfolio and work-related API interactions
 * 
 * @typedef {Object} WorkData
 * @property {string} description - Work description
 * @property {string} [client_id] - Associated client ID
 * @property {string} [project_id] - Associated project ID
 * @property {string} [work_order_id] - Associated work order ID
 */

import { API_CONFIG, buildQueryString } from './config';
import { apiClient } from './client';

export const portfolioService = {
    /**
     * Get portfolio with optional filters
     * @param {Object} [params] - Query parameters
     */
    async getPortfolio(params = {}) {
        try {
            const queryString = buildQueryString(params);
            return await apiClient.request(`${API_CONFIG.ENDPOINTS.PORTFOLIO.LIST}${queryString}`);
        } catch (error) {
            console.error('Error fetching portfolio:', error);
            throw error;
        }
    },

    /**
     * Create new work entry
     * @param {FormData} formData - Form data including files and work details
     * @param {Function} [onProgress] - Upload progress callback
     */
    async createWork(formData, onProgress) {
        try {
            // Validate form data
            this._validateFormData(formData);

            // Log form data in development
            if (process.env.NODE_ENV === 'development') {
                this._logFormData(formData);
            }

            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PORTFOLIO.CREATE,
                {
                    method: 'POST',
                    body: formData,
                    onProgress
                }
            );
        } catch (error) {
            console.error('Error creating work:', error);
            throw error;
        }
    },

    /**
     * Update existing work entry
     * @param {string} workId - Work ID
     * @param {WorkData} data - Updated work data
     */
    async updateWork(workId, data) {
        try {
            // Validate required fields
            if (!workId) throw new Error('Work ID is required');

            const response = await apiClient.request(
                API_CONFIG.ENDPOINTS.PORTFOLIO.UPDATE(workId),
                {
                    method: 'PUT',
                    body: JSON.stringify(data)
                }
            );

            return response;
        } catch (error) {
            console.error('Error updating work:', error);
            throw error;
        }
    },

    /**
     * Partially update work entry
     * @param {string} workId - Work ID
     * @param {Partial<WorkData>} data - Partial work data
     */
    async patchWork(workId, data) {
        try {
            if (!workId) throw new Error('Work ID is required');

            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PORTFOLIO.UPDATE(workId),
                {
                    method: 'PATCH',
                    body: JSON.stringify(data)
                }
            );
        } catch (error) {
            console.error('Error patching work:', error);
            throw error;
        }
    },

    /**
     * Delete work entry
     * @param {string} workId - Work ID
     */
    async deleteWork(workId) {
        try {
            if (!workId) throw new Error('Work ID is required');

            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PORTFOLIO.DELETE(workId),
                {
                    method: 'DELETE'
                }
            );
        } catch (error) {
            console.error('Error deleting work:', error);
            throw error;
        }
    },

    /**
     * Upload media files
     * @param {File[]} files - Array of files to upload
     * @param {string} [workId] - Optional work ID to associate files with
     * @param {Function} [onProgress] - Upload progress callback
     */
    async uploadMedia(files, workId = null, onProgress = null) {
        try {
            // Validate files
            if (!files || !files.length) {
                throw new Error('No files provided');
            }

            // Validate file types and sizes
            this._validateFiles(files);

            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append('files', file);
            });

            if (workId) {
                formData.append('work_id', workId);
            }

            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PORTFOLIO.UPLOAD,
                {
                    method: 'POST',
                    body: formData,
                    onProgress
                }
            );
        } catch (error) {
            console.error('Error uploading media:', error);
            throw error;
        }
    },

    /**
     * Associate work with work order
     * @param {string} workId - Work ID
     * @param {string} workOrderId - Work Order ID
     */
    async linkWorkOrder(workId, workOrderId) {
        try {
            return await this.patchWork(workId, { work_order_id: workOrderId });
        } catch (error) {
            console.error('Error linking work order:', error);
            throw error;
        }
    },

    // Private helper methods
    _validateFormData(formData) {
        const files = formData.getAll('files');
        if (!files.length) {
            throw new Error('No files included in form data');
        }
        this._validateFiles(files);
    },

    _validateFiles(files) {
        const maxFileSize = 50 * 1024 * 1024; // 50MB
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];

        files.forEach(file => {
            if (!allowedTypes.includes(file.type)) {
                throw new Error(`Invalid file type: ${file.type}`);
            }
            if (file.size > maxFileSize) {
                throw new Error(`File too large: ${file.name}`);
            }
        });
    },

    _logFormData(formData) {
        console.log('Creating work:', {
            hasToken: !!localStorage.getItem('accessToken'),
            formDataEntries: [...formData.entries()].map(([key, value]) => ({
                key,
                type: typeof value,
                isFile: value instanceof File,
                fileName: value instanceof File ? value.name : null
            }))
        });
    }
};