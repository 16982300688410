<!-- src/components/common/SessionModal.svelte -->
<script>
    import { fade } from 'svelte/transition';
    import { auth } from '../../stores/auth';
    import { createEventDispatcher } from 'svelte';
    import Login from '../auth/Login.svelte';

    const dispatch = createEventDispatcher();

    export let message = 'Your session has expired. Please log in again to continue.';
    let isLoggingIn = false;
    let error = null;

    async function handleLogin(event) {
        isLoggingIn = true;
        error = null;
        
        try {
            const { username, password } = event.detail;
            await auth.login(username, password);
            dispatch('reauthorized');
        } catch (err) {
            error = err.message;
        } finally {
            isLoggingIn = false;
        }
    }

    function handleClose() {
        if (!isLoggingIn) {
            auth.logout();
            window.location.href = '/';
        }
    }
</script>

<div 
    class="modal-overlay"
    on:click={handleClose}
    transition:fade={{ duration: 200 }}
>
    <div 
        class="modal-content"
        on:click|stopPropagation
    >
        <div class="modal-header">
            <h2>Session Expired</h2>
        </div>

        <div class="modal-body">
            {#if error}
                <div class="error-message" transition:fade>
                    {error}
                </div>
            {/if}

            <p>{message}</p>

            <Login 
                on:submit={handleLogin}
                {isLoggingIn}
            />
        </div>
    </div>
</div>

<style>
    .modal-overlay {
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1100;
        padding: 1rem;
    }

    .modal-content {
        background: white;
        border-radius: 0.5rem;
        padding: 1.5rem;
        width: 100%;
        max-width: 400px;
        position: relative;
        box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    h2 {
        margin: 0;
        font-size: 1.25rem;
        color: #1a202c;
    }

    .modal-body {
        padding: 0.5rem 0;
    }

    p {
        margin: 0 0 1.5rem;
        color: #4a5568;
    }

    .error-message {
        background: #fff5f5;
        color: #c53030;
        padding: 0.75rem;
        border-radius: 0.25rem;
        margin-bottom: 1rem;
        font-size: 0.875rem;
    }
</style>