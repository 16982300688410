<!-- src/components/portfolio/Portfolio.svelte -->
<script>
  import { onMount, onDestroy } from 'svelte';
  import { auth } from '../../stores/auth';
  import { clientStore } from '../../stores/client';
  import { worker } from '../../stores/worker';
  import { projectStore } from '../../stores/project';
  import { workOrderStore } from '../../stores/work-order';
  import { sessionManager } from '../../stores/sessionManager';

  import UserHeader from './UserHeader.svelte';
  import AuthModal from '../auth/AuthModal.svelte';
  import SessionModal from '../common/SessionModal.svelte';
  import ProjectForm from '../project/ProjectForm.svelte';
  import ClientList from '../client/ClientList.svelte';
  import WorkerList from '../workers/WorkerList.svelte';
  import ProjectList from '../project/ProjectList.svelte';
  import WorkOrderList from '../workorders/WorkOrderList.svelte';

  import { fade } from 'svelte/transition';

  // Core state flags
  let mounted = false;
  let dataInitialized = false;
  let dataLoadAttempted = false;
  let initialLoadComplete = false;

  // Modal states
  let showAuthModal = false;
  let showProjectModal = false;
  let showClientModal = false;
  let showWorkerModal = false;

  // Loading and error states
  let loading = {
    initial: true,
    clients: false,
    workers: false,
    projects: false,
    workOrders: false,
    lastLoadTime: null
  };

  let errors = {
    clients: null,
    workers: null,
    projects: null,
    workOrders: null
  };

  // Cache management
  const CACHE_TTL = 5 * 60 * 1000; // 5 minutes
  const STALE_WHILE_REVALIDATE = 30 * 60 * 1000; // 30 minutes
  let lastCacheUpdate = null;
  let refreshTimeout;
  let backgroundRefreshInterval;

  let updateTimeoutId;
  function debouncedUpdate(key, value, delay = 300) {
    if (updateTimeoutId) clearTimeout(updateTimeoutId);
    updateTimeoutId = setTimeout(() => {
      loading[key] = value;
      loading = loading; // Trigger reactivity
    }, delay);
  }

  function isCacheValid(allowStale = false) {
    if (!lastCacheUpdate) return false;
    const age = Date.now() - lastCacheUpdate;
    return allowStale 
      ? age < STALE_WHILE_REVALIDATE 
      : age < CACHE_TTL;
  }

  function updateCache() {
    lastCacheUpdate = Date.now();
    loading.lastLoadTime = Date.now();
    try {
      localStorage.setItem('portfolioLastUpdate', lastCacheUpdate.toString());
      localStorage.setItem('portfolioData', JSON.stringify({
        clients: clientStore.getState().items,
        workers: worker.getState().workers,
        projects: projectStore.getState().items,
        workOrders: workOrderStore.getState().items
      }));
    } catch (err) {
      console.warn('Failed to update cache:', err);
    }
  }

  function loadFromCache() {
    try {
      const timestamp = localStorage.getItem('portfolioLastUpdate');
      const cachedData = localStorage.getItem('portfolioData');
      
      if (timestamp && cachedData) {
        lastCacheUpdate = parseInt(timestamp);
        loading.lastLoadTime = lastCacheUpdate;
        
        const data = JSON.parse(cachedData);
        return data;
      }
    } catch (err) {
      console.warn('Failed to load from cache:', err);
    }
    return null;
  }

  async function loadClients(opts = {}) {
    const { force = false, background = false } = opts;
    if (!force && isCacheValid(background)) {
      return true;
    }
    if (!background) debouncedUpdate('clients', true);

    try {
      await clientStore.load({}, force);
      errors.clients = null;
      return true;
    } catch (err) {
      console.error('Failed to load clients:', err);
      if (err.status === 401) return false;
      if (!background) errors.clients = err.message;
      return false;
    } finally {
      if (!background) debouncedUpdate('clients', false);
    }
  }

  async function loadWorkers(opts = {}) {
    const { force = false, background = false } = opts;
    if (!force && isCacheValid(background)) {
      return true;
    }
    if (!background) debouncedUpdate('workers', true);

    try {
      await worker.loadWorkers();
      errors.workers = null;
      return true;
    } catch (err) {
      console.error('Failed to load workers:', err);
      if (err.status === 401) return false;
      if (!background) errors.workers = err.message;
      return false;
    } finally {
      if (!background) debouncedUpdate('workers', false);
    }
  }

  async function loadProjects(opts = {}) {
    const { force = false, background = false } = opts;
    if (!force && isCacheValid(background)) {
      return true;
    }
    if (!background) debouncedUpdate('projects', true);

    try {
      await projectStore.load({}, force);
      errors.projects = null;
      return true;
    } catch (err) {
      console.error('Failed to load projects:', err);
      if (err.status === 401) return false;
      if (!background) errors.projects = err.message;
      return false;
    } finally {
      if (!background) debouncedUpdate('projects', false);
    }
  }

  async function loadWorkOrders(opts = {}) {
    const { force = false, background = false } = opts;
    if (!force && isCacheValid(background)) {
      return true;
    }
    if (!background) debouncedUpdate('workOrders', true);

    try {
      await workOrderStore.load({}, force);
      errors.workOrders = null;
      return true;
    } catch (err) {
      console.error('Failed to load work orders:', err);
      if (err.status === 401) return false;
      if (!background) errors.workOrders = err.message;
      return false;
    } finally {
      if (!background) debouncedUpdate('workOrders', false);
    }
  }

  async function loadData(opts = {}) {
    const { force = false, background = false } = opts;
    if (!$auth.isAuthenticated) {
      console.log('Skipping data load: user not authenticated');
      return;
    }
    if (dataLoadAttempted && !force && !background) {
      console.log('Skipping duplicate load attempt');
      return;
    }

    if (!background) {
      loading.initial = true;
      dataLoadAttempted = true;
    }

    try {
      const cachedData = loadFromCache();
      if (!force && cachedData) {
        clientStore.set(cachedData.clients);
        worker.set(cachedData.workers);
        projectStore.set(cachedData.projects);
        workOrderStore.set(cachedData.workOrders);

        if (!background) {
          dataInitialized = true;
          initialLoadComplete = true;
          loading.initial = false;
        }
      }

      const results = await Promise.all([
        loadClients({ force, background }),
        loadWorkers({ force, background }),
        loadProjects({ force, background }),
        loadWorkOrders({ force, background })
      ]);

      if (results.every(Boolean)) {
        updateCache();
        dataInitialized = true;
        initialLoadComplete = true;
      }
    } catch (err) {
      console.error('Data load sequence error:', err);
      if (err.status === 401) {
        return;
      }
    } finally {
      if (!background) {
        loading.initial = false;
        dataInitialized = true;
        initialLoadComplete = true;
      }
    }
  }

  async function handleLogin(event) {
    try {
      console.log('Processing login...');
      await auth.login(event.detail.username, event.detail.password);
      showAuthModal = false;

      if ($auth.isAuthenticated) {
        console.log('Login successful, starting data load');
        await loadData({ force: true });
      }
    } catch (err) {
      console.error('Login handling error:', err);
    }
  }

  async function handleSessionReauthorized() {
    await sessionManager.handleReauthorized();
    await loadData({ force: true });
  }

  function setupBackgroundRefresh() {
    if (backgroundRefreshInterval) {
      clearInterval(backgroundRefreshInterval);
    }
    backgroundRefreshInterval = setInterval(() => {
      if ($auth.isAuthenticated && !loading.initial) {
        loadData({ background: true });
      }
    }, CACHE_TTL);
  }

  onMount(async () => {
    console.log('Portfolio mounting', {
      authenticated: $auth.isAuthenticated,
      mounted
    });
    mounted = true;

    if ($auth.isAuthenticated) {
      await loadData();
      setupBackgroundRefresh();
    } else {
      showAuthModal = true;
      dataInitialized = true;
    }
  });

  onDestroy(() => {
    if (backgroundRefreshInterval) clearInterval(backgroundRefreshInterval);
    if (updateTimeoutId) clearTimeout(updateTimeoutId);
    if (refreshTimeout) clearTimeout(refreshTimeout);
  });

  async function refreshSection(section) {
    console.log(`Refreshing ${section} data...`);
    errors[section] = null;

    try {
      switch (section) {
        case 'clients':
          await loadClients({ force: true });
          break;
        case 'workers':
          await loadWorkers({ force: true });
          break;
        case 'projects':
          await loadProjects({ force: true });
          break;
        case 'workOrders':
          await loadWorkOrders({ force: true });
          break;
      }
      updateCache();
    } catch (err) {
      console.error(`Error refreshing ${section}:`, err);
      errors[section] = err.message;
    }
  }
</script>

<!-- Initial Loading State -->
{#if !mounted || (!dataInitialized && loading.initial)}
  <div class="initial-loading" transition:fade>
    <div class="spinner"></div>
    <p>Starting application...</p>
  </div>
{:else}
  <div class="portfolio">
    <UserHeader />
    
    {#if $auth.isAuthenticated}
      <div class="content">
        <!-- Action Buttons -->
        <div class="action-buttons">
          <button
            class="action-button"
            on:click={() => (showClientModal = true)}
            disabled={loading.clients}
          >
            {#if loading.clients}
              <span class="loading-spinner"></span>
            {/if}
            Clients ({$clientStore?.items?.length || 0})
          </button>

          <button
            class="action-button"
            on:click={() => (showWorkerModal = true)}
            disabled={loading.workers}
          >
            {#if loading.workers}
              <span class="loading-spinner"></span>
            {/if}
            Workers ({$worker?.workers?.length || 0})
          </button>

          <button
            class="action-button create-project"
            on:click={() => (showProjectModal = true)}
            disabled={loading.projects}
          >
            Create Project
          </button>
        </div>

        <!-- Work Orders Section (moved above Projects) -->
        <section class="section" class:loading={loading.workOrders}>
          <div class="section-header">
            {#if loading.workOrders}
              <span class="loading-badge">Updating...</span>
            {/if}
            {#if errors.workOrders}
              <div class="error-badge">
                Error: {errors.workOrders}
                <button
                  class="retry-button"
                  on:click={() => refreshSection('workOrders')}
                >
                  Retry
                </button>
              </div>
            {/if}
          </div> 
          
          <WorkOrderList />
        </section>

        <!-- Projects Section (now comes after Work Orders) -->
        <!-- Projects Section (now comes after Work Orders) -->
<section class="section" class:loading={loading.projects}>
  <div class="section-header">
    <h2>Projects</h2>
    {#if loading.projects}
      <span class="loading-badge">Updating...</span>
    {/if}
    {#if errors.projects}
      <div class="error-badge">
        Error: {errors.projects}
        <button
          class="retry-button"
          on:click={() => refreshSection('projects')}
        >
          Retry
        </button>
      </div>
    {/if}
  </div>
  
  <!-- Just the ProjectList without its header/controls -->
  <div class="project-list-wrapper">
    <ProjectList />
  </div>
</section>

        {#if loading.lastLoadTime}
          <div class="last-update">
            Last updated: {new Date(loading.lastLoadTime).toLocaleTimeString()}
          </div>
        {/if}
      </div>
    {/if}
  </div>
{/if}

<!-- Modals -->
{#if $sessionManager.showSessionModal}
  <SessionModal
    message={$sessionManager.lastError?.message || 'Your session has expired. Please log in again to continue.'}
    on:reauthorized={handleSessionReauthorized}
    on:close={() => {
      sessionManager.closeSessionModal();
      auth.logout();
    }}
  />
{/if}

{#if showAuthModal && !$auth.isAuthenticated}
  <AuthModal
    on:close={() => (showAuthModal = false)}
    on:login={handleLogin}
  />
{/if}

{#if showProjectModal}
  <div class="modal-overlay">
    <div class="modal-container">
      <ProjectForm
        on:close={() => (showProjectModal = false)}
        on:submit={async () => {
          showProjectModal = false;
          await refreshSection('projects');
        }}
      />
    </div>
  </div>
{/if}

{#if showClientModal}
  <div class="modal-overlay">
    <div class="modal-container">
      <ClientList
        on:close={() => (showClientModal = false)}
        on:clientCreated={async () => {
          await refreshSection('clients');
        }}
      />
    </div>
  </div>
{/if}

{#if showWorkerModal}
  <div class="modal-overlay">
    <div class="modal-container">
      <WorkerList
        on:close={() => (showWorkerModal = false)}
        on:workerCreated={async () => {
          await refreshSection('workers');
        }}
      />
    </div>
  </div>
{/if}

<style>
  .portfolio {
    min-height: 100vh;
    background: #f8fafc;
  }

  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }

  .initial-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: #6b7280;
  }

  .spinner {
    width: 40px;
    height: 40px;
    margin-bottom: 1rem;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .loading-spinner {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid currentColor;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .section {
    margin-bottom: 2rem;
    background: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: opacity 0.2s ease;
  }

  .section.loading {
    opacity: 0.7;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .section-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .action-button {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    background: white;
    border: 1px solid #e2e8f0;
    color: #1a202c;
  }

  .action-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .action-button:not(:disabled):hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .create-project {
    background: #2196F3;
    color: white;
    border: none;
  }

  .create-project:not(:disabled):hover {
    background: #1976D2;
  }

  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-container {
    background: white;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
  }

  .loading-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    background: #f3f4f6;
    color: #6b7280;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }

  .error-badge {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #fee2e2;
    color: #dc2626;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }

  .retry-button {
    padding: 0.25rem 0.5rem;
    background: #dc2626;
    color: white;
    border: none;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    cursor: pointer;
  }

  .retry-button:hover {
    background: #b91c1c;
  }

  .last-update {
    text-align: center;
    color: #6b7280;
    font-size: 0.875rem;
    margin-top: 2rem;
  }
</style>
