// services/api/client.js
import { API_CONFIG, getFullUrl } from './config';
import { sessionManager } from '../../stores/sessionManager';

class ApiError extends Error {
    constructor(message, status, headers = null) {
        super(message);
        this.name = 'ApiError';
        this.status = status;
        this.headers = headers;
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

class ApiClient {
    constructor() {
        this.defaultHeaders = {
            'Content-Type': 'application/json'
        };
        this.maxRetries = 3;
        this.retryDelay = 1000; // Base delay in ms
    }

    async request(endpoint, options = {}, retryCount = 0) {
        const url = getFullUrl(endpoint);
        const accessToken = localStorage.getItem('accessToken');
        let headers = { ...this.defaultHeaders };

        // Don't set Content-Type for FormData
        if (options.body instanceof FormData) {
            delete headers['Content-Type'];
        }

        // Add JWT token if available
        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }

        // Handle FormData with progress tracking
        if (options.onProgress && options.body instanceof FormData) {
            return this.handleFormDataRequest(url, headers, options, retryCount);
        }

        // Standard fetch request
        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...headers,
                    ...options.headers
                }
            });

            if (!response.ok) {
                const error = await this.handleErrorResponse(response);
                
                // Handle token expiration
                if (error.status === 401 && accessToken) {
                    try {
                        await this.refreshToken();
                        return this.request(endpoint, options);
                    } catch (refreshError) {
                        this.clearAuth();
                        
                        // Add request to pending queue for retry after reauth
                        sessionManager.addPendingRequest(() => this.request(endpoint, options));
                        
                        // Handle session expiry through session manager
                        await sessionManager.handleSessionExpired(error);
                        
                        // If we get here, user has successfully reauthorized
                        return this.request(endpoint, options);
                    }
                }

                // Handle rate limiting
                if (error.status === 429 && retryCount < this.maxRetries) {
                    const retryAfter = parseInt(error.headers?.get('Retry-After') || '1');
                    const delay = this.calculateRetryDelay(retryCount, retryAfter);
                    await this.delay(delay);
                    return this.request(endpoint, options, retryCount + 1);
                }

                throw error;
            }

            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                return await response.json();
            }
            return await response.text();
        } catch (error) {
            console.error('[ApiClient] Request failed:', {
                endpoint,
                error: error.message
            });
            throw error;
        }
    }

    async handleFormDataRequest(url, headers, options, retryCount) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(options.method || 'GET', url);

            // Add headers
            Object.entries({ ...headers, ...options.headers }).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value);
            });

            // Add progress handler
            xhr.upload.onprogress = options.onProgress;

            xhr.onload = async () => {
                try {
                    const response = JSON.parse(xhr.responseText);
                    if (xhr.status >= 200 && xhr.status < 300) {
                        resolve(response);
                    } else if (xhr.status === 401) {
                        try {
                            await this.refreshToken();
                            const result = await this.request(url, options);
                            resolve(result);
                        } catch (refreshError) {
                            this.clearAuth();
                            sessionManager.addPendingRequest(() => this.request(url, options));
                            try {
                                await sessionManager.handleSessionExpired(
                                    new ApiError('Session expired during file upload', 401)
                                );
                                const result = await this.request(url, options);
                                resolve(result);
                            } catch (error) {
                                reject(error);
                            }
                        }
                    } else if (xhr.status === 429 && retryCount < this.maxRetries) {
                        const retryAfter = parseInt(xhr.getResponseHeader('Retry-After') || '1');
                        const delay = this.calculateRetryDelay(retryCount, retryAfter);
                        await this.delay(delay);
                        try {
                            const result = await this.request(url, options, retryCount + 1);
                            resolve(result);
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        reject(new ApiError(response.detail || response.error || 'Request failed', xhr.status));
                    }
                } catch (e) {
                    reject(new ApiError('Invalid server response', xhr.status));
                }
            };

            xhr.onerror = () => {
                reject(new ApiError('Network error', 0));
            };

            xhr.send(options.body);
        });
    }

    async handleErrorResponse(response) {
        let errorMessage = `Error ${response.status}`;
        try {
            const data = await response.json();
            errorMessage = data.detail || data.message || errorMessage;
        } catch (e) {
            try {
                const text = await response.text();
                if (text) errorMessage = text;
            } catch (textError) {
                console.error('Could not parse error response');
            }
        }
        return new ApiError(errorMessage, response.status, response.headers);
    }

    async refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        const response = await fetch(getFullUrl(API_CONFIG.ENDPOINTS.AUTH.REFRESH), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh: refreshToken })
        });

        if (!response.ok) {
            throw new Error('Token refresh failed');
        }

        const data = await response.json();
        localStorage.setItem('accessToken', data.access);
        return data.access;
    }

    calculateRetryDelay(retryCount, retryAfter = 1) {
        // Exponential backoff with jitter
        const exponentialDelay = Math.min(
            this.retryDelay * Math.pow(2, retryCount) + Math.random() * 1000,
            30000 // Max 30 seconds
        );
        return Math.max(retryAfter * 1000, exponentialDelay);
    }

    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    clearAuth() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
    }
}

export const apiClient = new ApiClient();