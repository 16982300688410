<script>
  import { onDestroy } from 'svelte';
  import { auth } from '../../stores/auth';
  import { createEventDispatcher } from 'svelte';
  import { navigate } from 'svelte-routing';

  const dispatch = createEventDispatcher();

  let username = '';
  let password = '';
  let confirmPassword = '';
  let passwordError = '';
  let loading = false;
  let error = null;
  let navigationAttempted = false;

  const unsubscribe = auth.subscribe(($auth) => {
    try {
      loading = $auth.isLoading;
      error = $auth.error;
    } catch (err) {
      console.error('Auth store subscription error:', err);
      error = 'System error occurred. Please try again.';
    }
  });

  onDestroy(() => {
    try {
      unsubscribe();
      // Clear sensitive data
      username = '';
      password = '';
      confirmPassword = '';
    } catch (err) {
      console.error('Cleanup error:', err);
    }
  });

  function validatePasswords() {
    if (!password || !confirmPassword) {
      passwordError = 'Both passwords are required';
      return false;
    }
    if (password !== confirmPassword) {
      passwordError = 'Passwords do not match';
      return false;
    }
    if (password.length < 8) {
      passwordError = 'Password must be at least 8 characters long';
      return false;
    }
    passwordError = '';
    return true;
  }

  async function handleSubmit() {
    if (loading || navigationAttempted || !validatePasswords()) return;

    try {
      loading = true;
      error = null;
      navigationAttempted = false;

      if (!username?.trim()) {
        throw new Error('Username is required');
      }

      // Attempt registration (auto-logs in on success)
      const response = await auth.register(username, password);
      
      // If registration + auto-login was successful
      if (response?.success || response?.access) {
        username = '';
        password = '';
        confirmPassword = '';
        dispatch('success');

        console.log('Registration + auto-login successful, navigating to portfolio...');
        try {
          navigationAttempted = true;
          // If your portfolio is at "/", update to: navigate('/')
          // If your portfolio is at "/portfolio", do: navigate('/portfolio')
          await navigate('/', { replace: true });
        } catch (navError) {
          console.error('Navigation failed:', navError);
          window.location.href = '/';
        }
      } else {
        throw new Error('Registration failed');
      }
    } catch (err) {
      error = err.message || 'Registration failed. Please try again.';
      console.error('Registration/auto-login error:', { 
        error: err, 
        timestamp: new Date().toISOString() 
      });
      password = '';
      confirmPassword = '';
    } finally {
      loading = false;
    }
  }

  function handleInput() {
    // Clear password mismatch error if user corrects them
    if (passwordError && password === confirmPassword) {
      passwordError = '';
    }
  }
</script>

<form on:submit|preventDefault={handleSubmit} class="auth-form">
  <h2>Create Account</h2>
  
  <div class="form-group">
    <label for="register-username">Username</label>
    <input
      type="text"
      id="register-username"
      bind:value={username}
      placeholder="Choose a username"
      disabled={loading}
      required
      minlength="3"
    />
  </div>

  <div class="form-group">
    <label for="register-password">Password</label>
    <input
      type="password"
      id="register-password"
      bind:value={password}
      on:input={handleInput}
      placeholder="Choose a password"
      disabled={loading}
      required
      minlength="8"
    />
  </div>

  <div class="form-group">
    <label for="confirm-password">Confirm Password</label>
    <input
      type="password"
      id="confirm-password"
      bind:value={confirmPassword}
      on:input={handleInput}
      placeholder="Confirm your password"
      disabled={loading}
      required
    />
  </div>

  {#if passwordError}
    <div class="error-message" role="alert">
      {passwordError}
    </div>
  {/if}

  {#if error}
    <div class="error-message" role="alert">
      {error}
    </div>
  {/if}

  <button 
    type="submit" 
    disabled={loading || !username || !password || !confirmPassword}
    class:loading={loading}
  >
    {loading ? 'Creating Account...' : 'Register'}
  </button>
</form>

<style>
  .auth-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #2c3e50;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #2c3e50;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
  }

  input:focus {
    outline: none;
    border-color: #007bff;
  }

  input:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
  }

  button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
  }

  button:not(:disabled):hover {
    background-color: #0056b3;
    transform: translateY(-1px);
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
  }

  .loading {
    opacity: 0.8;
    cursor: wait;
  }

  .error-message {
    color: #dc3545;
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: #f8d7da;
    border-radius: 4px;
    text-align: center;
    font-size: 0.9rem;
  }
</style>
