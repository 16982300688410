// description/ai/store/state.ts

import { writable } from 'svelte/store';
import type { RawAnnotation } from '../../../media/ai/store/vision-store';

/**
 * A reviewed annotation includes the original raw annotation data,
 * plus a status ('approved' or 'rejected') and a timestamp.
 */
export interface ReviewedAnnotation extends RawAnnotation {
    status: 'approved' | 'rejected';
    reviewedAt: Date;
}

/**
 * A Svelte store to hold all 'reviewed' annotations.
 */
export const reviewedAnnotations = writable<ReviewedAnnotation[]>([]);

/**
 * Additional Svelte stores for user input and generated steps,
 * retaining existing functionality.
 */
export const userInput = writable<string>('');
export const generatedSteps = writable<Array<{ content: string; status: string }>>([]);

/**
 * Debug subscriber for reviewed annotations.
 * Logs the count of total, approved, and rejected annotations whenever the store updates.
 */
reviewedAnnotations.subscribe(value => {
    console.group('[State Store] Reviewed Annotations Update');
    console.log('Total annotations:', value.length);
    console.log('Approved:', value.filter(a => a.status === 'approved').length);
    console.log('Rejected:', value.filter(a => a.status === 'rejected').length);
    console.log('Full list:', value);
    console.groupEnd();
});

/**
 * Actions to approve or reject annotations, updating the reviewedAnnotations store.
 */
export const annotationActions = {
    approve: (annotation: RawAnnotation) => {
        console.group('[State Store] Approving Annotation');
        console.log('Incoming annotation:', annotation);

        reviewedAnnotations.update(annotations => {
            // Check if this annotation ID has already been reviewed
            if (annotations.some(a => a.id === annotation.id)) {
                console.log('Annotation already reviewed; skipping.');
                console.groupEnd();
                return annotations;
            }

            const reviewed: ReviewedAnnotation = {
                ...annotation,
                status: 'approved',
                reviewedAt: new Date()
            };

            console.log('Created reviewed annotation:', reviewed);
            console.groupEnd();
            return [...annotations, reviewed];
        });
    },

    reject: (annotation: RawAnnotation) => {
        console.group('[State Store] Rejecting Annotation');
        console.log('Incoming annotation:', annotation);

        reviewedAnnotations.update(annotations => {
            // Check if this annotation ID has already been reviewed
            if (annotations.some(a => a.id === annotation.id)) {
                console.log('Annotation already reviewed; skipping.');
                console.groupEnd();
                return annotations;
            }

            const reviewed: ReviewedAnnotation = {
                ...annotation,
                status: 'rejected',
                reviewedAt: new Date()
            };

            console.log('Created reviewed annotation:', reviewed);
            console.groupEnd();
            return [...annotations, reviewed];
        });
    }
};
