<!-- src/components/client/ClientList.svelte -->
<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import { fade, slide } from 'svelte/transition';
    import { clientStore } from '../../stores/client';
    import ClientForm from './ClientForm.svelte';

    const dispatch = createEventDispatcher();
    
    let showForm = false;
    let searchTerm = '';
    let isLoading = true;
    let error = null;
    
    $: filteredClients = ($clientStore.items || [])
        .filter(client => {
            if (!searchTerm) return true;
            const search = searchTerm.toLowerCase();
            return (
                client.name?.toLowerCase().includes(search) ||
                client.contact_email?.toLowerCase().includes(search) ||
                client.phone_number?.toLowerCase().includes(search)
            );
        });

    function handleClose() {
        showForm = false;
        dispatch('close');
    }

    async function loadClients() {
        isLoading = true;
        error = null;
        
        try {
            await clientStore.load();
        } catch (err) {
            error = err.message;
            console.error('Error loading clients:', err);
        } finally {
            isLoading = false;
        }
    }

    async function handleClientCreated() {
        await loadClients();
        showForm = false;
    }

    onMount(loadClients);
</script>

<div class="client-list-container">
    <!-- Header -->
    <div class="list-header">
        <button class="close-btn" on:click={handleClose}>×</button>
        <h2>Clients</h2>
        <button class="add-btn" on:click={() => showForm = true}>
            Add Client
        </button>
    </div>

    <!-- Content -->
    {#if !showForm}
        <div class="controls">
            <input
                type="text"
                placeholder="Search clients..."
                bind:value={searchTerm}
                class="search-input"
            />
        </div>

        <!-- Client List -->
        {#if isLoading}
            <div class="loading">Loading clients...</div>
        {:else if error}
            <div class="error">
                {error}
                <button on:click={loadClients}>Retry</button>
            </div>
        {:else if filteredClients.length === 0}
            <div class="empty">
                {searchTerm ? 'No matching clients found' : 'No clients added yet'}
                <button class="add-first" on:click={() => showForm = true}>
                    Add Your First Client
                </button>
            </div>
        {:else}
            <div class="client-grid">
                {#each filteredClients as client}
                    <div class="client-card">
                        <div class="client-info">
                            <h3>{client.name}</h3>
                            <div class="contact-info">
                                {#if client.contact_email}
                                    <a href="mailto:{client.contact_email}" class="contact email">
                                        ✉️ {client.contact_email}
                                    </a>
                                {/if}
                                {#if client.phone_number}
                                    <a href="tel:{client.phone_number}" class="contact phone">
                                        📞 {client.phone_number}
                                    </a>
                                {/if}
                            </div>
                            {#if client.notes}
                                <p class="notes">{client.notes}</p>
                            {/if}
                        </div>
                        <div class="client-status">
                            <span class="status-badge {client.is_active ? 'active' : 'inactive'}">
                                {client.is_active ? 'Active' : 'Inactive'}
                            </span>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
    {:else}
        <ClientForm 
            on:close={() => showForm = false}
            on:created={handleClientCreated}
        />
    {/if}
</div>

<style>
    .client-list-container {
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }

    .list-header h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }

    .close-btn {
        font-size: 2rem;
        background: none;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .close-btn:hover {
        background: #f3f4f6;
    }

    .add-btn {
        background: #2563eb;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        height: 48px;
    }

    .add-btn:hover {
        background: #1d4ed8;
    }

    .controls {
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 64px;
        background: white;
        z-index: 9;
    }

    .search-input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        font-size: 1rem;
        height: 48px;
    }

    .client-grid {
        padding: 1rem;
        display: grid;
        gap: 1rem;
        overflow-y: auto;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .client-card {
        padding: 1.5rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        transition: all 0.2s ease;
    }

    .client-card:hover {
        border-color: #d1d5db;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);
    }

    .client-info {
        flex: 1;
    }

    .client-info h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 0.75rem 0;
        color: #111827;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .contact {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #2563eb;
        text-decoration: none;
        font-size: 0.875rem;
        padding: 0.25rem 0;
    }

    .contact:hover {
        color: #1d4ed8;
    }

    .notes {
        margin: 0.75rem 0 0 0;
        padding-top: 0.75rem;
        border-top: 1px solid #e5e7eb;
        color: #6b7280;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .client-status {
        flex-shrink: 0;
        margin-left: 1rem;
    }

    .status-badge {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        border-radius: 9999px;
        font-size: 0.75rem;
        font-weight: 500;
    }

    .status-badge.active {
        background: #dcfce7;
        color: #166534;
    }

    .status-badge.inactive {
        background: #f3f4f6;
        color: #4b5563;
    }

    .loading,
    .error,
    .empty {
        padding: 2rem;
        text-align: center;
        color: #6b7280;
    }

    .error {
        color: #dc2626;
        background: #fee2e2;
        border-radius: 0.5rem;
        margin: 1rem;
    }

    .add-first {
        margin-top: 1rem;
        color: #2563eb;
        text-decoration: underline;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;
    }

    /* Mobile optimizations */
    @media (max-width: 640px) {
        .client-grid {
            grid-template-columns: 1fr;
            padding: 1rem;
        }

        .search-input {
            font-size: 16px; /* Prevent iOS zoom */
        }

        .client-card {
            padding: 1rem;
        }

        .contact {
            padding: 0.5rem 0; /* Larger touch target */
        }
    }
</style>