/**
 * work-order-service.js
 * Handles all work order related API interactions
 * 
 * @typedef {Object} WorkOrderFilters
 * @property {string} [status] - Current status of the work order
 * @property {string} [priority] - Priority level
 * @property {string} [worker] - Assigned worker ID
 * @property {string} [dateRange] - Date range filter
 * 
 * @typedef {Object} WorkOrderData
 * @property {string} title - Work order title
 * @property {string} description - Work order description
 * @property {string} project - Project ID
 * @property {string} [priority] - Priority level
 * @property {Date} [scheduledStart] - Scheduled start date
 * @property {Date} [scheduledEnd] - Scheduled end date
 * 
 * @typedef {Object} WorkOrderUpdate
 * @property {string} updateText - Update content
 * @property {number} hoursWorked - Hours worked
 * @property {Object} [materialsUsed] - Materials used in the update
 */

import { API_CONFIG, buildQueryString } from './config';
import { apiClient } from './client';

export const workOrderService = {
    /**
     * Get work orders with optional filtering
     * @param {Object} params - Query parameters
     */
    async getWorkOrders(params = {}) {
        const queryString = buildQueryString(params);
        return await apiClient.request(`${API_CONFIG.ENDPOINTS.WORK_ORDER.LIST}${queryString}`);
    },

    /**
     * Get a specific work order by ID
     * @param {string} id - Work order ID
     */
    async getWorkOrderById(id) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.UPDATE(id),
                { method: 'GET' }
            );
        } catch (error) {
            console.error('Error fetching work order:', error);
            throw error;
        }
    },

    /**
     * Create a new work order
     * @param {WorkOrderData} workOrderData - Work order data
     */
    async createWorkOrder(workOrderData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.CREATE,
                {
                    method: 'POST',
                    body: JSON.stringify(workOrderData)
                }
            );
        } catch (error) {
            console.error('Error creating work order:', error);
            throw error;
        }
    },

    /**
     * Update an existing work order
     * @param {string} id - Work order ID
     * @param {WorkOrderData} workOrderData - Updated work order data
     */
    async updateWorkOrder(id, workOrderData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.UPDATE(id),
                {
                    method: 'PUT',
                    body: JSON.stringify(workOrderData)
                }
            );
        } catch (error) {
            console.error('Error updating work order:', error);
            throw error;
        }
    },

    /**
     * Partially update a work order
     * @param {string} id - Work order ID
     * @param {Partial<WorkOrderData>} partialData - Partial work order data
     */
    async patchWorkOrder(id, partialData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.UPDATE(id),
                {
                    method: 'PATCH',
                    body: JSON.stringify(partialData)
                }
            );
        } catch (error) {
            console.error('Error patching work order:', error);
            throw error;
        }
    },

    /**
     * Delete a work order
     * @param {string} id - Work order ID
     */
    async deleteWorkOrder(id) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.DELETE(id),
                {
                    method: 'DELETE'
                }
            );
        } catch (error) {
            console.error('Error deleting work order:', error);
            throw error;
        }
    },

    /**
     * Assign a worker to a work order
     * @param {string} workOrderId - Work order ID
     * @param {string} workerId - Worker ID
     * @param {string} [role='PRIMARY'] - Worker's role in the work order
     */
    async assignWorker(workOrderId, workerId, role = 'PRIMARY') {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.ASSIGN_WORKER(workOrderId),
                {
                    method: 'POST',
                    body: JSON.stringify({ worker_id: workerId, role })
                }
            );
        } catch (error) {
            console.error('Error assigning worker:', error);
            throw error;
        }
    },

    /**
     * Add an update to a work order
     * @param {string} workOrderId - Work order ID
     * @param {WorkOrderUpdate} updateData - Update data
     */
    async addUpdate(workOrderId, updateData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.ADD_UPDATE(workOrderId),
                {
                    method: 'POST',
                    body: JSON.stringify(updateData)
                }
            );
        } catch (error) {
            console.error('Error adding work order update:', error);
            throw error;
        }
    },

    /**
     * Start a work order
     * @param {string} workOrderId - Work order ID
     */
    async startWork(workOrderId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORK_ORDER.START(workOrderId),
                {
                    method: 'POST'
                }
            );
        } catch (error) {
            console.error('Error starting work order:', error);
            throw error;
        }
    },

   
     async completeWork(workOrderId) {
    try {
        return await apiClient.request(
            API_CONFIG.ENDPOINTS.WORK_ORDER.COMPLETE(workOrderId),
            {
                method: 'POST'
            }
        );
    } catch (error) {
        console.error('Error completing work order:', error);
        throw error;
    }
},

    /**
     * Get filtered work orders
     * @param {WorkOrderFilters} filters - Filter parameters
     */
    async getFilteredWorkOrders({ 
        status = null, 
        priority = null, 
        worker = null, 
        dateRange = null 
    } = {}) {
        const params = {
            ...(status && { status }),
            ...(priority && { priority }),
            ...(worker && { worker }),
            ...(dateRange && { date_range: dateRange })
        };
        return await this.getWorkOrders(params);
    },

    /**
     * Get work orders for a specific project
     * @param {string} projectId - Project ID
     */
    async getProjectWorkOrders(projectId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.PROJECT.WORK_ORDERS(projectId)
            );
        } catch (error) {
            console.error('Error fetching project work orders:', error);
            throw error;
        }
    }
};