// src/stores/client.js
import { clientService } from '../services/api';
import { createBaseStore } from './base-store';

const clientStore = createBaseStore({
    name: 'client',
    cacheTtl: 10 * 60 * 1000, // 10 minutes - longer TTL as client data changes less frequently
    loadFn: filters => clientService.getClients(filters),
    addFn: data => clientService.createClient(data),
    updateFn: (id, data) => clientService.updateClient(id, data),
    deleteFn: id => clientService.deleteClient(id)
});

export { clientStore };