<!-- App.svelte -->
<script>
  import { Router, Link, Route } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { auth } from './stores/auth';
  import { navigate } from 'svelte-routing';
  
  // Component imports
  import Portfolio from './components/portfolio/Portfolio.svelte';
  import CreateWork from './components/create/CreateWork.svelte';
  import AuthModal from './components/auth/AuthModal.svelte';
  
  export let url = '';
  let showAuthModal = false;
  let currentPath = window.location.pathname;
  let mounted = false;

  onMount(() => {
    // Check auth status on mount
    auth.checkAuth();
    mounted = true;

    // Update currentPath when route changes
    const handleRouteChange = () => {
      currentPath = window.location.pathname;
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  });

  function handleNavigationClick(event, path) {
    event.preventDefault();
    if (!$auth.isAuthenticated) {
      showAuthModal = true;
      return;
    }
    navigate(path);
  }

  function handleAuthSuccess() {
    showAuthModal = false;
    // Navigate to the intended path after successful auth
    navigate(currentPath);
  }
</script>

{#if mounted}
<Router {url}>
  <main class="app-container">
    <!-- Navigation buttons -->
    <nav class="nav-buttons">
      {#if currentPath !== '/'}
        <Link
          to="/"
          class="fab create-btn"
          aria-label="Create new work"
          on:click={(e) => handleNavigationClick(e, '/')}
        >
          <!-- Some icon -->
        </Link>
      {/if}

      {#if currentPath !== '/create'}
        <Link
          to="/create"
          class="fab portfolio-btn"
          aria-label="Create work"
          on:click={(e) => handleNavigationClick(e, '/create')}
        >
          <!-- Some icon -->
        </Link>
      {/if}
    </nav>

    <!-- Routes -->
    <!-- Make portfolio the default route -->
    <Route path="/" component={Portfolio} />
    <Route path="create" component={CreateWork} />
  </main>
</Router>
{/if}

{#if showAuthModal}
  <AuthModal
    on:close={() => showAuthModal = false}
    on:success={handleAuthSuccess}
  />
{/if}

<style>
  .app-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }

  .nav-buttons {
    position: fixed;
    right: 30px;
    top: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 100;
  }

  .fab {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    display: grid;
    place-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .create-btn {
    background-color: #2196F3;
    color: white;
  }

  .portfolio-btn {
    background-color: white;
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .fab:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    .nav-buttons {
      top: auto;
      bottom: 30px;
      right: 50%;
      transform: translateX(50%);
      flex-direction: row;
      padding: 0.75rem;
      background: white;
      border-radius: 16px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    }

    .fab {
      width: 48px;
      height: 48px;
    }
  }
</style>