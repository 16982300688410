// src/main.js
import App from './App.svelte';
import './styles/tokens.css';  // First, so variables are available
import './styles/app.css';     // Then global styles

const app = new App({
  target: document.body,
});

export default app;
