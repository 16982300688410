<!-- src/components/workers/WorkerList.svelte -->
<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import { fade, slide } from 'svelte/transition';
    import { worker } from '../../stores/worker';
    import WorkerForm from './WorkerForm.svelte';

    const dispatch = createEventDispatcher();
    
    let showForm = false;
    let searchTerm = '';
    let statusFilter = 'ALL';
    let isLoading = true;
    let error = null;

    // Filter workers based on search and status
    $: filteredWorkers = ($worker.workers || [])
        .filter(w => statusFilter === 'ALL' || w.status === statusFilter)
        .filter(w => {
            if (!searchTerm) return true;
            const search = searchTerm.toLowerCase();
            return (
                w.name?.toLowerCase().includes(search) ||
                w.phone?.toLowerCase().includes(search) ||
                w.email?.toLowerCase().includes(search)
            );
        });

    function handleClose() {
        showForm = false;
        dispatch('close');
    }

    async function loadWorkers() {
        isLoading = true;
        error = null;
        
        try {
            await worker.loadWorkers();
        } catch (err) {
            error = err.message;
            console.error('Error loading workers:', err);
        } finally {
            isLoading = false;
        }
    }

    async function handleWorkerCreated() {
        await loadWorkers();
        showForm = false;
    }

    onMount(loadWorkers);
</script>

<!-- Main Container -->
<div class="worker-list-container">
    <!-- Header -->
    <div class="list-header">
        <button class="close-btn" on:click={handleClose}>&times;</button>
        <h2>Workers</h2>
        <button class="add-btn" on:click={() => showForm = true}>
            Add Worker
        </button>
    </div>

    <!-- Search & Filters -->
    {#if !showForm}
        <div class="controls">
            <input
                type="text"
                placeholder="Search workers..."
                bind:value={searchTerm}
                class="search-input"
            />
            
            <div class="status-filters">
                <button 
                    class="status-btn {statusFilter === 'ALL' ? 'active' : ''}"
                    on:click={() => statusFilter = 'ALL'}
                >
                    All
                </button>
                <button 
                    class="status-btn {statusFilter === 'AVAILABLE' ? 'active' : ''}"
                    on:click={() => statusFilter = 'AVAILABLE'}
                >
                    Available
                </button>
                <button 
                    class="status-btn {statusFilter === 'BUSY' ? 'active' : ''}"
                    on:click={() => statusFilter = 'BUSY'}
                >
                    Busy
                </button>
            </div>
        </div>

        <!-- Worker List -->
        {#if isLoading}
            <div class="loading">Loading workers...</div>
        {:else if error}
            <div class="error">
                {error}
                <button on:click={loadWorkers}>Retry</button>
            </div>
        {:else if filteredWorkers.length === 0}
            <div class="empty">
                {searchTerm ? 'No matching workers found' : 'No workers added yet'}
                <button class="add-first" on:click={() => showForm = true}>
                    Add Your First Worker
                </button>
            </div>
        {:else}
            <div class="worker-grid">
                {#each filteredWorkers as worker}
                    <div class="worker-card">
                        <div class="worker-info">
                            <h3>{worker.name}</h3>
                            {#if worker.phone}
                                <a href="tel:{worker.phone}" class="contact phone">
                                    📞 {worker.phone}
                                </a>
                            {/if}
                            {#if worker.email}
                                <a href="mailto:{worker.email}" class="contact email">
                                    ✉️ {worker.email}
                                </a>
                            {/if}
                        </div>
                        <div class="status {worker.status.toLowerCase()}">
                            {worker.status}
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
    {:else}
        <WorkerForm 
            on:close={() => showForm = false}
            on:created={handleWorkerCreated}
        />
    {/if}
</div>

<style>
    .worker-list-container {
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
    }

    .list-header h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }

    .close-btn {
        font-size: 2rem;
        background: none;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .close-btn:hover {
        background: #f3f4f6;
    }

    .add-btn {
        background: #2563eb;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        height: 48px;
    }

    .add-btn:hover {
        background: #1d4ed8;
    }

    .controls {
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
    }

    .search-input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        height: 48px;
    }

    .status-filters {
        display: flex;
        gap: 0.5rem;
        overflow-x: auto;
        padding-bottom: 0.5rem;
    }

    .status-btn {
        background: #f3f4f6;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 9999px;
        font-size: 1rem;
        cursor: pointer;
        white-space: nowrap;
        height: 48px;
    }

    .status-btn.active {
        background: #2563eb;
        color: white;
    }

    .worker-grid {
        padding: 1rem;
        display: grid;
        gap: 1rem;
        overflow-y: auto;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .worker-card {
        padding: 1rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .worker-info h3 {
        font-size: 1.125rem;
        font-weight: 500;
        margin: 0 0 0.5rem 0;
    }

    .contact {
        display: block;
        color: #2563eb;
        text-decoration: none;
        margin-bottom: 0.25rem;
        font-size: 1rem;
        padding: 0.25rem 0;
    }

    .status {
        padding: 0.5rem 1rem;
        border-radius: 9999px;
        font-size: 0.875rem;
        font-weight: 500;
    }

    .status.available {
        background: #dcfce7;
        color: #166534;
    }

    .status.busy {
        background: #fef9c3;
        color: #854d0e;
    }

    .status.on_leave {
        background: #dbeafe;
        color: #1e40af;
    }

    .status.inactive {
        background: #f3f4f6;
        color: #4b5563;
    }

    .loading, .error, .empty {
        padding: 2rem;
        text-align: center;
        color: #6b7280;
    }

    .error {
        color: #dc2626;
    }

    .add-first {
        margin-top: 1rem;
        color: #2563eb;
        text-decoration: underline;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;
    }

    /* Mobile optimizations */
    @media (max-width: 640px) {
        .worker-grid {
            grid-template-columns: 1fr;
        }

        .status-filters {
            margin: 0 -1rem;
            padding: 0 1rem;
        }

        .list-header {
            position: sticky;
            top: 0;
            background: white;
            z-index: 10;
        }

        .controls {
            position: sticky;
            top: 64px;
            background: white;
            z-index: 10;
        }
    }
</style>