<!-- src/components/project/ProjectList.svelte -->
<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import { projectStore } from '../../stores/project';
    import ProjectForm from './ProjectForm.svelte';
    
    const dispatch = createEventDispatcher();
    let showForm = false;
    let searchTerm = '';
    let statusFilter = 'ALL';
    let isLoading = true;
    let error = null;

    $: filteredProjects = ($projectStore.items || [])
        .filter(p => statusFilter === 'ALL' || p.status === statusFilter)
        .filter(p => {
            if (!searchTerm) return true;
            const s = searchTerm.toLowerCase();
            return (
                p.title?.toLowerCase().includes(s) ||
                p.description?.toLowerCase().includes(s) ||
                p.client_name?.toLowerCase().includes(s)
            );
        });

    function handleClose() {
        showForm = false;
        dispatch('close');
    }

    async function loadProjects() {
        isLoading = true;
        error = null;
        
        try {
            await projectStore.load();
        } catch (err) {
            error = err.message;
            console.error('Error loading projects:', err);
        } finally {
            isLoading = false;
        }
    }

    async function handleProjectCreated(event) {
        showForm = false;
        await loadProjects();
    }

    function getStatusColor(status) {
        switch (status) {
            case 'PLANNING': return 'planning';
            case 'IN_PROGRESS': return 'in-progress';
            case 'ON_HOLD': return 'on-hold';
            case 'COMPLETED': return 'completed';
            case 'CANCELLED': return 'cancelled';
            default: return 'default';
        }
    }

    function formatDate(date) {
        if (!date) return '';
        return new Date(date).toLocaleDateString();
    }

    // Format budget as US currency
    function formatCurrency(amount) {
        if (!amount) return '';
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    }

    onMount(loadProjects);
</script>

<div class="project-list-container">
   

    <!-- Content -->
    {#if !showForm}
        <div class="controls">
            <input
                type="text"
                placeholder="Search projects..."
                bind:value={searchTerm}
                class="search-input"
            />
            <div class="status-filters">
                <button 
                    class="status-btn {statusFilter === 'ALL' ? 'active' : ''}"
                    on:click={() => statusFilter = 'ALL'}
                >
                    All
                </button>
                <button 
                    class="status-btn {statusFilter === 'IN_PROGRESS' ? 'active' : ''}"
                    on:click={() => statusFilter = 'IN_PROGRESS'}
                >
                    In Progress
                </button>
                <button 
                    class="status-btn {statusFilter === 'PLANNING' ? 'active' : ''}"
                    on:click={() => statusFilter = 'PLANNING'}
                >
                    Planning
                </button>
                <button 
                    class="status-btn {statusFilter === 'ON_HOLD' ? 'active' : ''}"
                    on:click={() => statusFilter = 'ON_HOLD'}
                >
                    On Hold
                </button>
            </div>
        </div>

        {#if isLoading}
            <div class="loading">Loading projects...</div>
        {:else if error}
            <div class="error">
                {error}
                <button on:click={loadProjects}>Retry</button>
            </div>
        {:else if filteredProjects.length === 0}
            <div class="empty">
                {searchTerm || statusFilter !== 'ALL' ? 'No matching projects found' : 'No projects added yet'}
                <button class="add-first" on:click={() => showForm = true}>
                    Create Your First Project
                </button>
            </div>
        {:else}
            <div class="project-grid">
                {#each filteredProjects as project}
                    <div class="project-card">
                        <div class="project-header">
                            <div class="project-title">
                                <h3>{project.title}</h3>
                                <span class="status-badge {getStatusColor(project.status)}">
                                    {project.status}
                                </span>
                            </div>
                            {#if project.budget}
                                <div class="budget">
                                    {formatCurrency(project.budget)}
                                </div>
                            {/if}
                        </div>

                        {#if project.client_name}
                            <div class="client-info">
                                <span class="label">Client:</span>
                                <span class="value">{project.client_name}</span>
                            </div>
                        {/if}

                        {#if project.description}
                            <p class="description">{project.description}</p>
                        {/if}

                        <div class="project-details">
                            <div class="dates">
                                {#if project.start_date}
                                    <div class="date">
                                        <span class="label">Start:</span>
                                        <span class="value">{formatDate(project.start_date)}</span>
                                    </div>
                                {/if}
                                {#if project.end_date}
                                    <div class="date">
                                        <span class="label">Est. End:</span>
                                        <span class="value">{formatDate(project.end_date)}</span>
                                    </div>
                                {/if}
                            </div>

                            <div class="stats">
                                <div class="stat">
                                    <span class="label">Work Orders:</span>
                                    <span class="value">
                                        {project.active_work_orders_count || 0} active
                                    </span>
                                </div>
                                <div class="stat">
                                    <span class="label">Completed:</span>
                                    <span class="value">
                                        {project.completed_work_orders_count || 0}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
    {:else}
        <ProjectForm 
            on:close={() => showForm = false}
            on:created={handleProjectCreated}
        />
    {/if}
</div>

<style>
    .project-list-container {
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }

    .list-header h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }

    .close-btn {
        font-size: 2rem;
        background: none;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .close-btn:hover {
        background: #f3f4f6;
    }

    .add-btn {
        background: #2563eb;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        height: 48px;
    }

    .add-btn:hover {
        background: #1d4ed8;
    }

    .controls {
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        position: sticky;
        top: 64px;
        background: white;
        z-index: 9;
    }

    .search-input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.5rem;
        font-size: 1rem;
        height: 48px;
        margin-bottom: 1rem;
    }

    .status-filters {
        display: flex;
        gap: 0.5rem;
        overflow-x: auto;
        padding-bottom: 0.5rem;
        -webkit-overflow-scrolling: touch;
    }

    .status-btn {
        background: #f3f4f6;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 9999px;
        font-size: 1rem;
        cursor: pointer;
        white-space: nowrap;
        height: 48px;
    }

    .status-btn.active {
        background: #2563eb;
        color: white;
    }

    .project-grid {
        padding: 1rem;
        display: grid;
        gap: 1rem;
        overflow-y: auto;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    .project-card {
        padding: 1.5rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        background: white;
        transition: all 0.2s ease;
    }

    .project-card:hover {
        border-color: #d1d5db;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);
    }

    .project-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;
    }

    .project-title {
        flex: 1;
        margin-right: 1rem;
    }

    .project-title h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 0.5rem 0;
        color: #111827;
    }

    .status-badge {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        border-radius: 9999px;
        font-size: 0.75rem;
        font-weight: 500;
    }

    .status-badge.planning { background: #dbeafe; color: #1e40af; }
    .status-badge.in-progress { background: #dcfce7; color: #166534; }
    .status-badge.on-hold { background: #fef3c7; color: #92400e; }
    .status-badge.completed { background: #f3f4f6; color: #4b5563; }
    .status-badge.cancelled { background: #fee2e2; color: #dc2626; }

    .budget {
        font-weight: 600;
        color: #059669;
        font-size: 1.125rem;
    }

    .client-info {
        margin-bottom: 1rem;
        font-size: 0.875rem;
    }

    .description {
        margin: 0 0 1rem;
        color: #6b7280;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .project-details {
        display: grid;
        gap: 1rem;
        font-size: 0.875rem;
        border-top: 1px solid #e5e7eb;
        padding-top: 1rem;
    }

    .dates {
        display: flex;
        gap: 1.5rem;
    }

    .date {
        display: flex;
        gap: 0.5rem;
    }

    .stats {
        display: flex;
        gap: 1.5rem;
    }

    .label {
        color: #6b7280;
        font-weight: 500;
    }

    .value {
        color: #111827;
    }

    .loading,
    .error,
    .empty {
        padding: 2rem;
        text-align: center;
        color: #6b7280;
    }

    .error {
        color: #dc2626;
        background: #fee2e2;
        border-radius: 0.5rem;
        margin: 1rem;
    }

    .add-first {
        margin-top: 1rem;
        color: #2563eb;
        text-decoration: underline;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;
    }

    /* Mobile optimizations */
    @media (max-width: 640px) {
        .project-grid {
            grid-template-columns: 1fr;
        }

        .search-input {
            font-size: 16px; /* Prevent iOS zoom */
        }

        .status-filters {
            margin: 0 -1rem;
            padding: 0 1rem 0.5rem;
        }

        .project-card {
            padding: 1rem;
        }

        .dates,
        .stats {
            flex-direction: column;
            gap: 0.5rem;
        }
    }
</style>