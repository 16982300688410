/**
 * worker-service.js
 * Handles all worker-related API interactions
 * 
 * @typedef {Object} WorkerData
 * @property {string} name - Worker's name
 * @property {string} [title] - Worker's job title
 * @property {string} [email] - Worker's email
 * @property {string} [phone] - Worker's phone number
 * @property {string} [status] - Worker's availability status
 * @property {Object} [skills] - Worker's skills and certifications
 * @property {number} [hourly_rate] - Worker's hourly rate
 */

import { API_CONFIG, buildQueryString } from './config';
import { apiClient } from './client';

export const workerService = {
    /**
     * Get workers with optional filtering
     * @param {Object} [params] - Query parameters
     */
    async getWorkers(params = {}) {
        try {
            const queryString = buildQueryString(params);
            return await apiClient.request(`${API_CONFIG.ENDPOINTS.WORKER.LIST}${queryString}`);
        } catch (error) {
            console.error('Error fetching workers:', error);
            throw error;
        }
    },

    /**
     * Get a specific worker by ID
     * @param {string} workerId - Worker ID
     */
    async getWorkerById(workerId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.UPDATE(workerId),
                { method: 'GET' }
            );
        } catch (error) {
            console.error('Error fetching worker:', error);
            throw error;
        }
    },

    /**
     * Create a new worker
     * @param {WorkerData} workerData - Worker data
     */
    async createWorker(workerData) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.CREATE,
                {
                    method: 'POST',
                    body: JSON.stringify(workerData)
                }
            );
        } catch (error) {
            console.error('Error creating worker:', error);
            throw error;
        }
    },

    /**
     * Update an existing worker
     * @param {string} workerId - Worker ID
     * @param {WorkerData} data - Updated worker data
     */
    async updateWorker(workerId, data) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.UPDATE(workerId),
                {
                    method: 'PUT',
                    body: JSON.stringify(data)
                }
            );
        } catch (error) {
            console.error('Error updating worker:', error);
            throw error;
        }
    },

    /**
     * Partially update a worker
     * @param {string} workerId - Worker ID
     * @param {Partial<WorkerData>} data - Partial worker data
     */
    async patchWorker(workerId, data) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.UPDATE(workerId),
                {
                    method: 'PATCH',
                    body: JSON.stringify(data)
                }
            );
        } catch (error) {
            console.error('Error patching worker:', error);
            throw error;
        }
    },

    /**
     * Delete a worker
     * @param {string} workerId - Worker ID
     */
    async deleteWorker(workerId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.DELETE(workerId),
                {
                    method: 'DELETE'
                }
            );
        } catch (error) {
            console.error('Error deleting worker:', error);
            throw error;
        }
    },

    /**
     * Get worker's assigned projects
     * @param {string} workerId - Worker ID
     */
    async getWorkerProjects(workerId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.PROJECTS(workerId)
            );
        } catch (error) {
            console.error('Error fetching worker projects:', error);
            throw error;
        }
    },

    /**
     * Get worker's current work orders
     * @param {string} workerId - Worker ID
     */
    async getCurrentWorkOrders(workerId) {
        try {
            return await apiClient.request(
                API_CONFIG.ENDPOINTS.WORKER.WORK_ORDERS(workerId)
            );
        } catch (error) {
            console.error('Error fetching worker work orders:', error);
            throw error;
        }
    },

    /**
     * Get worker's schedule
     * @param {string} workerId - Worker ID
     * @param {Object} [params] - Date range parameters
     */
    async getWorkerSchedule(workerId, params = {}) {
        try {
            const queryString = buildQueryString(params);
            return await apiClient.request(
                `${API_CONFIG.ENDPOINTS.WORKER.SCHEDULE(workerId)}${queryString}`
            );
        } catch (error) {
            console.error('Error fetching worker schedule:', error);
            throw error;
        }
    },

    /**
     * Update worker's availability status
     * @param {string} workerId - Worker ID
     * @param {string} status - New status (AVAILABLE, BUSY, ON_LEAVE, INACTIVE)
     */
    async updateWorkerStatus(workerId, status) {
        try {
            return await this.patchWorker(workerId, { status });
        } catch (error) {
            console.error('Error updating worker status:', error);
            throw error;
        }
    },

    /**
     * Get workers filtered by availability and skills
     * @param {Object} filters - Filter parameters
     * @param {string} [filters.status] - Availability status
     * @param {string[]} [filters.skills] - Required skills
     */
    async getAvailableWorkers(filters = {}) {
        try {
            const params = {
                status: filters.status || 'AVAILABLE',
                skills: filters.skills ? JSON.stringify(filters.skills) : undefined
            };
            return await this.getWorkers(params);
        } catch (error) {
            console.error('Error fetching available workers:', error);
            throw error;
        }
    },

    /**
     * Get worker's work statistics
     * @param {string} workerId - Worker ID
     */
    async getWorkerStats(workerId) {
        try {
            const [currentWorkOrders, schedule] = await Promise.all([
                this.getCurrentWorkOrders(workerId),
                this.getWorkerSchedule(workerId)
            ]);

            return {
                activeWorkOrders: currentWorkOrders.length,
                scheduledHours: this._calculateScheduledHours(schedule),
                completedOrders: schedule.filter(s => s.status === 'COMPLETED').length
            };
        } catch (error) {
            console.error('Error fetching worker statistics:', error);
            throw error;
        }
    },

    // Private helper method
    _calculateScheduledHours(schedule) {
        return schedule.reduce((total, entry) => {
            if (entry.scheduled_start && entry.scheduled_end) {
                // Convert dates to numeric timestamps so TypeScript
                // recognizes the arithmetic as valid.
                const start = new Date(entry.scheduled_start).getTime();
                const end = new Date(entry.scheduled_end).getTime();
                const hours = (end - start) / (1000 * 60 * 60);
                return total + hours;
            }
            return total;
        }, 0);
    }
};
